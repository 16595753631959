import { BackendTranslation, toBackendTranslations } from '@/common/secondary/BackendTranslation';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { BackendClubTaxDeductionToUpdate, toBackendClubTaxDeductionToUpdate } from '@/staff/secondary/club/BackendClubTaxDeductionToUpdate';

export interface BackendClubToUpdate {
  name?: string;
  platformName?: string;
  logoUrl?: string;
  bannerUrl?: string;
  contactEmail?: string;
  taxDeduction?: BackendClubTaxDeductionToUpdate;
  recipientEmails?: string[];
  presentationTranslations?: BackendTranslation[];
}

export const toBackendClubToUpdate = (clubToUpdate: ClubToUpdate): BackendClubToUpdate => ({
  name: clubToUpdate.name.orElseUndefined(),
  platformName: clubToUpdate.platformName.orElseUndefined(),
  logoUrl: clubToUpdate.logoUrl.orElseUndefined(),
  bannerUrl: clubToUpdate.bannerUrl.orElseUndefined(),
  contactEmail: clubToUpdate.contactEmail.orElseUndefined(),
  taxDeduction: clubToUpdate.taxDeduction.map(toBackendClubTaxDeductionToUpdate).orElseUndefined(),
  recipientEmails: clubToUpdate.recipientEmails.orElseUndefined(),
  presentationTranslations: toBackendTranslations(clubToUpdate.presentationTranslations),
});
