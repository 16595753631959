import { ExclusiveToCreate } from '@/staff/domain/exclusive/ExclusiveToCreate';
import { Url } from '@/common/domain/Url';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';
import { BackendAuctionLotToCreate, fromAuctionLotToCreate } from '@/staff/secondary/exclusive/BackendAuctionLotToCreate';
import { BackendTaxDeductionToCreate, fromTaxDeductionToCreate } from '@/staff/secondary/exclusive/BackendTaxDeductionToCreate';

export interface BackendExclusiveToCreate {
  slug: string;
  imageUrl: Url;
  medias: BackendMedia[];
  priceCoinsAmount: number;
  visible: boolean;
  stock: number;
  category: string;
  auctionLot?: BackendAuctionLotToCreate;
  taxDeduction?: BackendTaxDeductionToCreate;
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

export const toBackendExclusiveToCreate = (exclusiveToCreate: ExclusiveToCreate): BackendExclusiveToCreate => ({
  slug: exclusiveToCreate.slug,
  imageUrl: exclusiveToCreate.imageUrl,
  medias: exclusiveToCreate.medias.map(toBackendMedia),
  priceCoinsAmount: exclusiveToCreate.priceCoinsAmount,
  visible: exclusiveToCreate.visible,
  stock: exclusiveToCreate.stock,
  category: exclusiveToCreate.category,
  auctionLot: exclusiveToCreate.auctionLot.map(fromAuctionLotToCreate).orElseUndefined(),
  taxDeduction: exclusiveToCreate.taxDeduction.map(fromTaxDeductionToCreate).orElseUndefined(),
  nameTranslations: exclusiveToCreate.nameTranslations.map(toBackendTranslation<ExclusiveName>),
  descriptionTranslations: exclusiveToCreate.descriptionTranslations.map(toBackendTranslation<ExclusiveDescription>),
});
