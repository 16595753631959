import { computed, defineComponent, inject, PropType, ref, watch } from 'vue';
import { uploadRepositoryKey } from '@/staff/domain/upload/UploadRepository';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { MarkdownTranslationVue } from '@/common/primary/markdown/translation';
import { Media } from '@/common/domain/Media';
import { MediaInputLineVue } from '@/staff/primary/media-input/media-input-line';
import { MediaType } from '@/common/domain/MediaType';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'MediasInput',

  components: { MarkdownTranslationVue, MediaInputLineVue },

  props: {
    medias: {
      type: Array as PropType<Media[]>,
      required: true,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    touched: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    accept: {
      type: Array as PropType<MediaType[]>,
      required: true,
    },
  },

  emits: ['updated-medias', 'unsupported-files'],

  setup(props, { emit }) {
    const uploadRepository = inject(uploadRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const dragCounter = ref(0);

    const currentMedias = ref([...props.medias]);

    const dragging = computed(() => dragCounter.value > 0);
    const acceptedFileTypes = computed(() => {
      const mediaTypeToAcceptedFileType: { [key in MediaType]: string } = {
        [MediaType.IMAGE]: 'image/*',
        [MediaType.VIDEO]: 'video/*',
      };
      return props.accept.map(acceptedFileType => mediaTypeToAcceptedFileType[acceptedFileType]).join(',');
    });
    const mediaRequired = computed(() => props.required && props.medias.length === 0);

    watch(
      () => [props.medias],
      () => {
        currentMedias.value = [...props.medias];
      }
    );

    const toMediaType = (file: File): MediaType => {
      if (file.type.startsWith('image/')) {
        return MediaType.IMAGE;
      }
      return MediaType.VIDEO;
    };

    const toLoadingMedia = (file: File): Media => ({
      url: 'loading',
      type: toMediaType(file),
    });

    const uploadMedias = async (files: File[]): Promise<Media[]> => {
      const uploadedMedias: Media[] = [];
      for (const file of Array.from(files)) {
        try {
          const mediaType = toMediaType(file);
          const presignedUpload = await uploadRepository.createPresignedUpload(club.slug, file.type);
          await uploadRepository.uploadFileToStorage(file, presignedUpload.url);
          uploadedMedias.push({ url: presignedUpload.futureMediaUrl, type: mediaType });
        } catch (error: any) {
          logger.error('Failed to upload media', error);
        }
      }
      return uploadedMedias;
    };

    const filterAcceptedFiles = (file: File) =>
      (props.accept.includes(MediaType.IMAGE) && file.type.startsWith('image/')) ||
      (props.accept.includes(MediaType.VIDEO) && file.type.startsWith('video/'));

    const updateMedias = async (files: FileList) => {
      if (!files.length) {
        return;
      }
      const acceptedFiles = Array.from(files).filter(filterAcceptedFiles);
      emit('unsupported-files', { value: files.length - acceptedFiles.length });
      const loadingMedias = acceptedFiles.map(toLoadingMedia).filter(media => media !== null);
      if (loadingMedias.length) {
        currentMedias.value = props.multiple ? [...props.medias, ...loadingMedias] : [loadingMedias[0]];
      }

      const uploadedMedias = await uploadMedias(acceptedFiles);

      if (uploadedMedias.length) {
        if (props.multiple) {
          emit('updated-medias', { value: [...props.medias, ...uploadedMedias] });
        } else {
          emit('updated-medias', { value: uploadedMedias });
        }
      }
    };

    const moveUp = (index: number) => {
      if (index > 0) {
        const previousMedia = currentMedias.value[index - 1];
        currentMedias.value[index - 1] = currentMedias.value[index];
        currentMedias.value[index] = previousMedia;

        emit('updated-medias', { value: currentMedias.value });
      }
    };

    const moveDown = (index: number) => {
      if (index < currentMedias.value.length - 1) {
        const nextMedia = currentMedias.value[index + 1];
        currentMedias.value[index + 1] = currentMedias.value[index];
        currentMedias.value[index] = nextMedia;

        emit('updated-medias', { value: currentMedias.value });
      }
    };

    const deleteMedia = (indexToRemove: number) => {
      const updatedMedias = currentMedias.value.filter((_, index) => index !== indexToRemove);

      emit('updated-medias', { value: updatedMedias });
    };

    const dropFile = (event: DragEvent) => {
      updateMedias(event.dataTransfer!.files);
      dragCounter.value = 0;
    };

    const changeFile = (event: Event) => updateMedias((<HTMLInputElement>event.target).files!);

    const onDragEnter = () => dragCounter.value++;

    const onDragLeave = () => dragCounter.value--;

    return {
      moveUp,
      moveDown,
      dragging,
      acceptedFileTypes,
      mediaRequired,
      onDragEnter,
      onDragLeave,
      currentMedias,
      deleteMedia,
      dropFile,
      changeFile,
      updateMedias,
    };
  },
});
