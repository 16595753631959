import { filterExclusives } from '@/staff/primary/Exclusives.ui';
import { computed, defineComponent, inject, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { DropdownVue } from '@/common/primary/dropdown';
import { DropdownItemVue } from '@/common/primary/dropdown/dropdown-item';
import { DropdownSeparatorVue } from '@/common/primary/dropdown/dropdown-separator';
import { ExclusiveUi } from '@/staff/primary/exclusive/Exclusive.ui';
import { FairplayerButtonVue } from '@/common/primary/button';

export default defineComponent({
  name: 'AuctionExclusivesTable',

  props: {
    auctionId: {
      type: String as PropType<string>,
      required: true,
    },
    exclusives: {
      type: Object as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  components: { DropdownVue, DropdownSeparatorVue, DropdownItemVue, FairplayerButtonVue },

  setup(props) {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const terms = ref('');
    const showNotVisibleExclusives = ref(true);

    const club = fromClub(clubRepository.getCurrentClub());

    const hasExclusives = computed(() => props.exclusives.length > 0);

    const filteredExclusives = computed(() => filterExclusives(props.exclusives, showNotVisibleExclusives.value, terms.value));

    return {
      club,
      filteredExclusives,
      hasExclusives,
      showNotVisibleExclusives,
      terms,
      t,
    };
  },
});
