.fp-staff-layout--body--header
  BreadcrumbVue(:platform-name='club.platformName', :club-slug='club.slug')
  .fp-horizontal-spacing.-s-sm.-items-center
    .fp-horizontal-spacing--column
      DropdownVue(name='profile')
        template(v-slot:trigger)
          .fp-vertical-spacing.-justify-center.-s-xs
            .fp-vertical-spacing--line.-align-right
              .fp-text.-color-shade-900.-text-sm.-text-semi-bold {{ stafferName }}
            .fp-vertical-spacing--line.-align-right
              .fp-text.-text-sm {{ club.name }}
        DropdownItemVue(:text='t(\'menu.language.en\')' image='flag-en' @click='setLanguage(\'en\')' data-test='language-en')
        DropdownItemVue(:text='t(\'menu.language.fr\')' image='flag-fr' @click='setLanguage(\'fr\')' data-test='language-fr')
        DropdownSeparatorVue
        DropdownItemVue(:text='t(\'menu.theme.light\')' icon='sun' @click='setTheme(\'light\')' data-test='theme-light')
        DropdownItemVue(:text='t(\'menu.theme.dark\')' icon='moon' @click='setTheme(\'dark\')' data-test='theme-dark')
        DropdownSeparatorVue
        template(v-if='!clubs.isLoading() && clubs.value().length > 1')
          DropdownItemVue(:text='t(\'menu.switchClub\')' icon='arrows-counter-clockwise' @click='switchClub' data-test='switch-club')
          DropdownSeparatorVue
        DropdownItemVue(danger :text='t(\'logout\')' icon='log-out' @click='logout' data-test='logout')
    .fp-horizontal-spacing--column
      .fp-logo.-sm
        img(:src='club.logoUrl', alt='Club Logo')
.fp-staff-layout--body--content
  .fp-staff-layout--body--content-wrapper
    slot
  SidebarVue
.fp-staff-layout--body--footer(v-if='slots.footer')
  slot(name='footer')
