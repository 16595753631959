import { BackendTranslation, toBackendTranslations } from '@/common/secondary/BackendTranslation';
import { ClubTaxDeductionToUpdate } from '@/staff/domain/club/ClubTaxDeductionToUpdate';

export interface BackendClubTaxDeductionToUpdate {
  allow?: boolean;
  signatureUrl?: string;
  textTranslations?: BackendTranslation[];
}

export const toBackendClubTaxDeductionToUpdate = (taxDeductionToUpdate: ClubTaxDeductionToUpdate): BackendClubTaxDeductionToUpdate => ({
  allow: taxDeductionToUpdate.allow.orElseUndefined(),
  signatureUrl: taxDeductionToUpdate.signatureUrl.orElseUndefined(),
  textTranslations: toBackendTranslations(taxDeductionToUpdate.textTranslations),
});
