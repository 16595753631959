import { BackendCaller } from '@/common/secondary/BackendCaller';
import { Club } from '@/staff/domain/club/Club';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ClubSummary } from '@/staff/domain/club/ClubSummary';
import { BackendClub, toClub } from '@/staff/secondary/club/BackendClub';
import { BackendClubSummary, toClubSummary } from '@/staff/secondary/club/BackendClubSummary';
import { BackendCoinsGift, sortCoinsGiftsByDateDesc, toCoinsGift } from '@/staff/secondary/club/BackendCoinsGift';
import { CoinsGifts } from '@/staff/domain/club/CoinsGifts';
import { Coin } from '@/common/domain/token/Coin';
import { BackendFansStatistics, toFansStatistics } from '@/staff/secondary/club/BackendFansStatistics';
import { FansStatistics } from '@/staff/domain/club/FansStatistics';
import { CoinsHoldings } from '@/staff/domain/club/CoinsHoldings';
import { BackendCoinsHolding, sortCoinsHoldingsByFanEmail, toCoinsHolding } from '@/staff/secondary/club/BackendCoinsHolding';
import { CoinsGiftToCreate } from '@/staff/domain/club/CoinsGiftToCreate';
import { toBackendCoinsGiftToCreate } from '@/staff/secondary/club/BackendCoinsGiftToCreate';
import { PresignedUpload } from '@/staff/domain/upload/PresignedUpload';
import { FileContentType } from '@/common/domain/FileContentType';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { toBackendClubToUpdate } from '@/staff/secondary/club/BackendClubToUpdate';
import { TranslationRequest } from '@/staff/domain/club/TranslationRequest';
import { toBackendTranslationRequest } from '@/staff/secondary/club/BackendTranslationRequest';
import { Email } from '@/staff/domain/Email';
import { Username } from '@/common/domain/Username';

export class BackendClubRepository {
  constructor(private backendCaller: BackendCaller) {}

  listFor(stafferUsername: Username): Promise<ClubSummary[]> {
    return this.backendCaller
      .get<BackendClubSummary[]>(`api/staffers/${encodeURIComponent(stafferUsername)}/clubs`)
      .then(clubs => clubs.data.map(toClubSummary));
  }

  getBySlug(clubSlug: ClubSlug): Promise<Club> {
    return this.backendCaller
      .get<BackendClub>(`api/clubs/${clubSlug}`, { params: { 'with-translations': true } })
      .then(clubs => toClub(clubs.data));
  }

  update(clubToUpdate: ClubToUpdate): Promise<Club> {
    return this.backendCaller
      .patch<BackendClub>(`api/clubs/${clubToUpdate.slug}`, toBackendClubToUpdate(clubToUpdate))
      .then(clubs => toClub(clubs.data));
  }

  getRecipientEmails(clubSlug: ClubSlug): Promise<Email[]> {
    return this.backendCaller.get<string[]>(`api/clubs/${clubSlug}/recipient-emails`).then(emails => emails.data);
  }

  getCoinsGifts(clubSlug: ClubSlug, coin: Coin): Promise<CoinsGifts> {
    return this.backendCaller
      .get<BackendCoinsGift[]>(`api/clubs/${clubSlug}/coins-gifts`)
      .then(coinsGifts => new CoinsGifts(coinsGifts.data.map(toCoinsGift).sort(sortCoinsGiftsByDateDesc), coin));
  }

  async createCoinsGift(clubSlug: ClubSlug, coinsGift: CoinsGiftToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/coins-gifts`, toBackendCoinsGiftToCreate(coinsGift));
  }

  getCoinsHoldings(clubSlug: ClubSlug, coin: Coin): Promise<CoinsHoldings> {
    return this.backendCaller
      .get<BackendCoinsHolding[]>(`api/clubs/${clubSlug}/coins-holdings`)
      .then(coinsHoldings => new CoinsHoldings(coinsHoldings.data.map(toCoinsHolding).sort(sortCoinsHoldingsByFanEmail), coin));
  }

  onboardPaymentPartner(clubSlug: ClubSlug, email: Email): Promise<string> {
    return this.backendCaller.put<string>(`api/clubs/${clubSlug}/payment-partner-account`, { email }).then(response => response.data);
  }

  getFansStatistics(clubSlug: ClubSlug): Promise<FansStatistics> {
    return this.backendCaller
      .get<BackendFansStatistics>(`api/clubs/${clubSlug}/fans-statistics`)
      .then(fansStatistics => toFansStatistics(fansStatistics.data));
  }

  createPresignedUpload(clubSlug: ClubSlug, contentType: FileContentType): Promise<PresignedUpload> {
    return this.backendCaller.post<PresignedUpload>(`api/clubs/${clubSlug}/presigned-uploads`, { contentType }).then(({ data }) => data);
  }

  async translateText(translationRequest: TranslationRequest): Promise<string> {
    return await this.backendCaller
      .post<string>(`api/clubs/${translationRequest.clubSlug}/translations`, toBackendTranslationRequest(translationRequest))
      .then(({ data }) => data);
  }
}
