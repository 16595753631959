PageVue
  .fp-vertical-spacing.-s-xl
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='payment-partner.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('paymentPartnerForm.title') }}
    .fp-vertical-spacing--line(v-if='club.paymentPartnerAccountStatus === ClubPaymentPartnerAccountStatusUi.NOT_ONBOARDED' data-test='form')
      PaymentPartnerFormVue#payment-partner-form(@confirm='onConfirm')
    .fp-vertical-spacing--line(v-if='club.paymentPartnerAccountStatus === ClubPaymentPartnerAccountStatusUi.ONBOARDING' data-test='onboarding')
      .fp-alert.-warning
        i.fp-icon.fp-glyph-hourglass-low.-color-warning.-s18
        span.fp-text {{ t('paymentPartnerForm.onboarding') }}
    .fp-vertical-spacing--line(v-if='club.paymentPartnerAccountStatus === ClubPaymentPartnerAccountStatusUi.VALIDATED' data-test='validated')
      .fp-alert.-success
        i.fp-icon.fp-glyph-check.-color-success.-s18
        span.fp-text {{ t('paymentPartnerForm.validated') }}
    .fp-vertical-spacing--line(v-if='club.paymentPartnerAccountStatus === ClubPaymentPartnerAccountStatusUi.IN_ERROR' data-test='in-error')
      .fp-alert.-warning
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line
            i.fp-icon.fp-glyph-warning.-color-warning.-s18
            span.fp-text &nbsp; {{ t('paymentPartnerForm.inError') }}
          .fp-vertical-spacing--line
            a.fp-link(href='https://dashboard.stripe.com/account/status' target='_blank' data-test='go-to-stripe-in-error') {{ t('paymentPartnerForm.goToStripeErrorLink') }}
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='payment-partner.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column(v-if='club.paymentPartnerAccountStatus === ClubPaymentPartnerAccountStatusUi.NOT_ONBOARDED')
        FairplayerButtonVue(:text='t(\'paymentPartnerForm.onboard\')' type='submit' form='payment-partner-form' icon='arrow-square-in' :loading='onboarding' data-test='payment-partner.submit')
      .fp-horizontal-spacing--column(v-else)
        a.fp-link.-no-text-decoration(href='https://dashboard.stripe.com/account/status' target='_blank' data-test='go-to-stripe')
          FairplayerButtonVue(icon='arrow-square-in' :text='t(\'paymentPartnerForm.goToStripe\')')
