import { Loader } from '@/common/primary/loader/Loader';
import { computed, defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { auctionRepositoryKey } from '@/staff/domain/exclusive/AuctionRepository';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { BidWithExclusiveUi, fromBidWithExclusive } from '@/staff/primary/auction/auction-details/BidWithExclusive.ui';
import { AuctionBidsTableVue } from '@/staff/primary/auction/auction-details/auction-bids-table';
import { AuctionStatisticsVue } from '@/staff/primary/auction/auction-details/auction-statistics';
import { AuctionExclusivesTableVue } from '@/staff/primary/auction/auction-details/auction-exclusives-table';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { updateAuctionModal } from '@/common/primary/modal/Modals';
import { UpdateAuctionModalOptions } from '@/staff/primary/auction/update-auction-modal/UpdateAuctionModalOptions';
import { fromAuction } from '@/staff/primary/exclusive/Auction.ui';
import { Auction } from '@/staff/domain/exclusive/Auction';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { fromExclusive } from '@/staff/primary/exclusive/Exclusive.ui';

export default defineComponent({
  name: 'AuctionDetails',

  props: {
    auctionId: {
      type: String as PropType<string>,
      required: true,
    },
  },

  emits: ['auction-updated'],

  components: { AuctionExclusivesTableVue, RelativeTimeVue, AuctionBidsTableVue, AuctionStatisticsVue },

  setup(props, { emit }) {
    const { t } = useI18n();

    const auctionRepository = inject(auctionRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const modalBus = inject(modalBusKey)!;

    const bids = ref(Loader.loading<BidWithExclusiveUi[]>());
    const auction = ref(Loader.loading<Auction>());
    const auctionUi = computed(() => fromAuction(auction.value.value()));

    const clubExclusives = ref(Loader.loading<Exclusive[]>());
    const exclusives = computed(() =>
      clubExclusives.value
        .value()
        .filter(e => e.auctionLot.filter(auctionLot => auctionLot.auction.id === props.auctionId).isPresent())
        .map(fromExclusive)
    );
    const club = fromClub(clubRepository.getCurrentClub());

    watch(
      () => props.auctionId,
      () => getAuctionDetails()
    );

    const getAuctionDetails = () => {
      getAuction();
      listBids();
    };

    onMounted(async () => {
      await listClubExclusives();
      getAuctionDetails();
    });

    const getAuction = () => {
      auction.value = Loader.loading<Auction>();
      auctionRepository
        .getById(clubRepository.getCurrentClub().slug, props.auctionId)
        .then(auctionRetrieved => auction.value.loaded(auctionRetrieved))
        .catch(error => {
          logger.error('Failed to retrieve auction', error);
        });
    };

    const listBids = () => {
      bids.value = Loader.loading<BidWithExclusiveUi[]>();
      auctionRepository
        .listAuctionBids(clubRepository.getCurrentClub().slug, props.auctionId)
        .then(bidsRetrieved => bids.value.loaded(bidsRetrieved.map(fromBidWithExclusive)))
        .catch(error => {
          bids.value.loaded([]);
          logger.error('Failed to retrieve auction bids', error);
        });
    };

    const listClubExclusives = () =>
      exclusiveRepository
        .getByClub(club.slug, club.coin)
        .then(exclusivesRetrieved => clubExclusives.value.loaded(exclusivesRetrieved.exclusives))
        .catch(error => {
          logger.error('Failed to retrieve club exclusives', error);
          clubExclusives.value.loaded([]);
        });

    const openUpdateAuctionModal = () => {
      const modal = updateAuctionModal();
      modalBus.open<UpdateAuctionModalOptions>({
        component: modal,
        options: {
          auction: auction.value.value()!,
          updated: onUpdated,
        },
      });
    };

    const onUpdated = () => {
      getAuction();

      emit('auction-updated');
    };

    return {
      auction,
      clubExclusives,
      exclusives,
      auctionUi,
      bids,
      t,
      FairplayerFallbackImageUi,
      openUpdateAuctionModal,
      onUpdated,
    };
  },
});
