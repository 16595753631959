import { ClubTaxDeduction } from '@/staff/domain/club/ClubTaxDeduction';
import { Language } from '@/common/domain/Language';
import { emptyTranslationsUi, fromTranslations, toUpdatedTranslations, TranslationUi } from '@/common/primary/Translation.ui';
import { ClubTaxDeductionToUpdate } from '@/staff/domain/club/ClubTaxDeductionToUpdate';
import { Optional } from '@/common/domain/Optional';

export interface ClubTaxDeductionFormUi {
  allow: boolean;
  signatureUrl: string;
  textTranslations: TranslationUi[];
}

export const fromClubTaxDeduction = (taxDeduction: ClubTaxDeduction, languages: Language[]): ClubTaxDeductionFormUi => ({
  allow: taxDeduction.allow,
  signatureUrl: taxDeduction.signatureUrl.orElse(''),
  textTranslations: fromTranslations(languages, taxDeduction.textTranslations!),
});

export const toClubTaxDeductionToUpdate = (
  taxDeductionForm: ClubTaxDeductionFormUi,
  taxDeduction: ClubTaxDeduction
): ClubTaxDeductionToUpdate => ({
  allow: toAllow(taxDeductionForm, taxDeduction),
  signatureUrl: toOptionalString(taxDeductionForm.signatureUrl, taxDeduction.signatureUrl.orElse('')),
  textTranslations: toUpdatedTranslations(taxDeduction.textTranslations!, taxDeductionForm.textTranslations),
});

const toAllow = (taxDeductionForm: ClubTaxDeductionFormUi, taxDeduction: ClubTaxDeduction): Optional<boolean> =>
  Optional.of(taxDeductionForm.allow).filter(allow => allow != taxDeduction.allow);

const toOptionalString = (formValue: string, domainValue: string): Optional<string> =>
  Optional.ofEmpty(formValue).filter(str => str.localeCompare(domainValue) !== 0);

export const emptyClubTaxDeductionForm = (languages: string[]): ClubTaxDeductionFormUi => ({
  allow: false,
  signatureUrl: '',
  textTranslations: emptyTranslationsUi(languages),
});
