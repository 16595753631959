import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayParticipation } from '@/staff/domain/club/giveaway/GiveawayParticipation';
import { GiveawayQuestionStatistics } from '@/staff/domain/club/giveaway/GiveawayQuestionStatistics';
import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';

export const giveawayRepositoryKey: InjectionKey<GiveawayRepository> = Symbol();

export interface GiveawayRepository {
  create(clubSlug: ClubSlug, giveawayToCreate: GiveawayToCreate): Promise<void>;
  list(clubSlug: ClubSlug): Promise<Giveaway[]>;
  get(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<Giveaway>;
  update(giveawayToUpdate: GiveawayToUpdate): Promise<Giveaway>;
  close(closureToCreate: GiveawayClosureToCreate): Promise<void>;
  listGiveawayParticipations(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayParticipation[]>;
  getQuestionsStatistics(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayQuestionStatistics[]>;
}
