PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='create-program.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('programForm.createTitle') }}
    .fp-vertical-spacing--line(v-if='!programForm.isLoading()')
      ProgramFormVue#create-program-form(:club-slug='clubSlug' :form-value='programForm.value()' @confirm='createProgram')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='create-program.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'programForm.createProgram\')' :loading='creating' type='submit' form='create-program-form' icon='plus' data-test='create-program.submit')
