import { defineComponent, inject, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { GiveawayQuestionTypeUi } from '@/staff/primary/giveaway/create-giveaway/GiveawayQuestionType.ui';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { emptyAnswerFormUi, GiveawayAnswerFormUi } from '@/staff/primary/giveaway/GiveawayAnswerForm.ui';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { TranslationUi } from '@/common/primary/Translation.ui';
import { Language } from '@/common/domain/Language';

const MINIMAL_QUESTION_ANSWERS = 2;

export default defineComponent({
  name: 'GiveawayFormQuestion',

  components: {
    MarkdownEditorVue,
  },

  props: {
    question: {
      type: Object as PropType<GiveawayQuestionFormUi>,
      required: true,
    },

    questionIndex: {
      type: Number as PropType<number>,
      required: true,
    },

    isLastQuestion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    limitedUpdate: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['question-updated', 'remove-question', 'up', 'down', 'clone-question', 'focus-language'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;

    const questionForm = ref(props.question);
    const isPreTitleShown = ref(questionForm.value.preTitle.value !== '');
    const questionTypes = Object.keys(GiveawayQuestionTypeUi);

    const club = clubRepository.getCurrentClub();

    const computePreTitleTranslations = (): TranslationUi[] | undefined => {
      if (!isPreTitleShown.value) {
        return [];
      }
      return questionForm.value.preTitleTranslations;
    };

    const computeMinimalAnswersIfMissing = (): GiveawayAnswerFormUi[] => {
      if (questionForm.value.type === GiveawayQuestionTypeUi.CHOICE && questionForm.value.answers.length < MINIMAL_QUESTION_ANSWERS) {
        questionForm.value.answers = [emptyAnswerFormUi(club.languages), emptyAnswerFormUi(club.languages)];
      }
      return questionForm.value.answers;
    };

    watch(
      () => [questionForm, isPreTitleShown],
      () =>
        emit('question-updated', {
          question: {
            ...questionForm.value,
            preTitleTranslations: computePreTitleTranslations(),
            answers: computeMinimalAnswersIfMissing(),
          },
          index: props.questionIndex,
        }),
      { deep: true }
    );

    const addAnswer = (): void => {
      questionForm.value.answers = [...props.question.answers, emptyAnswerFormUi(club.languages)];
    };

    const removeAnswer = (answerIdx: number): void => {
      const questionToUpdateAnswers = props.question.answers.filter((answer, idx) => idx !== answerIdx);
      if (
        !questionToUpdateAnswers.find(answer => answer.textTranslations[0].value == props.question.rightAnswer?.textTranslations[0].value)
      ) {
        questionForm.value.rightAnswer = props.question.answers[0];
      }
      questionForm.value.answers = questionToUpdateAnswers;
    };

    const cloneQuestion = () => {
      emit('clone-question');
    };

    const removeQuestion = () => {
      emit('remove-question');
    };

    const moveQuestionUp = () => {
      emit('up');
    };

    const moveQuestionDown = () => {
      emit('down');
    };

    const onInputFocus = (focusedLanguage: Language): void => {
      emit('focus-language', { value: focusedLanguage });
    };

    const onFocusOut = (translations: TranslationUi[], index: number): void => {
      const value = translations[index].value;

      translations.forEach(translation => {
        if (!translation.value) {
          translation.value = value;
        }
      });
    };

    const moveAnswerUp = (answerIdx: number) => {
      const previousAnswer = questionForm.value.answers[answerIdx - 1];
      questionForm.value.answers[answerIdx - 1] = questionForm.value.answers[answerIdx];
      questionForm.value.answers[answerIdx] = previousAnswer;
    };

    const moveAnswerDown = (answerIdx: number) => {
      const nextAnswer = questionForm.value.answers[answerIdx + 1];
      questionForm.value.answers[answerIdx + 1] = questionForm.value.answers[answerIdx];
      questionForm.value.answers[answerIdx] = nextAnswer;
    };

    const updatePreTitle = (translation: TranslationUi, updatedPreTitle: string) => {
      translation.value = updatedPreTitle;
    };

    const showPreTitle = () => {
      isPreTitleShown.value = true;
    };

    const hidePreTitle = () => {
      isPreTitleShown.value = false;
    };

    return {
      t,
      showPreTitle,
      cloneQuestion,
      removeQuestion,
      removeAnswer,
      addAnswer,
      onFocusOut,
      onInputFocus,
      questionTypes,
      questionForm,
      isPreTitleShown,
      hidePreTitle,
      updatePreTitle,
      MINIMAL_QUESTION_ANSWERS,
      moveQuestionUp,
      moveQuestionDown,
      moveAnswerUp,
      moveAnswerDown,
    };
  },
});
