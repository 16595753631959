import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';
import { TaxDeduction } from '@/staff/domain/exclusive/TaxDeduction';

export interface BackendTaxDeduction {
  nonDeductibleAmount: BackendFiat;
}

export const toTaxDeduction = (backendTaxDeduction: BackendTaxDeduction): TaxDeduction => ({
  nonDeductibleAmount: toFiat(backendTaxDeduction.nonDeductibleAmount),
});
