PageVue
  .fp-vertical-spacing.-s-xl
    .fp-vertical-spacing--line
      .fp-vertical-spacing
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-gavel.-s18
            .fp-horizontal-spacing--column.-expand
              h2.fp-title.-color-shade-700 {{ t('auctionsPage.title') }}
            .fp-horizontal-spacing--column
              FairplayerButtonVue(icon='copy' :text='t(\'auctionsPage.copyLiveAuction\')' @click='copyLiveAuctionUrl' secondary data-test='copy-live-auction')
            .fp-horizontal-spacing--column
              FairplayerButtonVue(icon='plus' :text='t(\'auctionsPage.createAuction\')' @click='openCreateAuctionModal' data-test='create-auction')
        .fp-vertical-spacing--line(v-if='!auctions.isLoading()')
          .fp-vertical-spacing(v-if='!auctions.value().length' data-test='no-auctions')
            .fp-vertical-spacing--line.-align-center
              i.fp-icon.fp-glyph-close-circle.-s50.-color-shade-300
            .fp-vertical-spacing--line.-align-center
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  .fp-text.-text-semi-bold.-text-lg {{ t('auctionsPage.empty.title') }}
                .fp-vertical-spacing--line.-align-center {{ t('auctionsPage.empty.noAuctions') }}
            .fp-vertical-spacing--line.-justify-center
              a.fp-link.fp-icon-with-text(@click='openCreateAuctionModal' data-test='create-auction')
                i.fp-icon.fp-glyph-plus
                | {{ t('auctionsPage.empty.create') }}
          .fp-responsive-grid.-s-sm.-cols3.-cols2-up-lg.-cols1-up-sm(v-else)
            .fp-responsive-grid--column(v-for='(auction, index) in auctions.value()' :key='auction.id')
              AuctionCardVue(:auction='auction' :is-selected='auction.id === selectedAuctionId' @selected='selectAuction(auction.id)')
    template(v-if='selectedAuctionId')
      .fp-separator.-secondary
      .fp-vertical-spacing--line(data-test='auction-details')
        AuctionDetailsVue(:auction-id='selectedAuctionId' @auction-updated='listAuctions')
