import router from '@/router/router';
import { PaymentPartnerFormUi } from '@/staff/primary/club/payment-partner-form/PaymentPartnerForm.ui';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PaymentPartnerFormVue } from '@/staff/primary/club/payment-partner-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { ClubPaymentPartnerAccountStatusUi } from '@/staff/primary/club/ClubPaymentPartnerAccountStatus.ui';

export default defineComponent({
  name: 'PaymentPartner',

  components: {
    PaymentPartnerFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const onboarding = ref(false);

    const onConfirm = (paymentPartnerForm: PaymentPartnerFormUi): Promise<void> => {
      onboarding.value = true;

      return clubRepository
        .onboardPaymentPartner(props.clubSlug, paymentPartnerForm.email)
        .then(onboardingUrl => pageRedirecter.navigateTo(onboardingUrl))
        .catch(error => {
          logger.error('Failed to onboard payment partner', error);
          onboarding.value = false;
        });
    };

    const onGoBack = () => {
      router.push({ name: 'updateClub', params: { clubSlug: props.clubSlug } });
    };

    return {
      club,
      onboarding,
      ClubPaymentPartnerAccountStatusUi,
      onGoBack,
      onConfirm,
      t,
    };
  },
});
