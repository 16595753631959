import { BackendTranslation, fromBackendTranslation } from '@/common/secondary/BackendTranslation';
import { Optional } from '@/common/domain/Optional';
import { ClubTaxDeduction } from '@/staff/domain/club/ClubTaxDeduction';
import { ClubTaxDeductionText } from '@/staff/domain/club/ClubTaxDeductionText';

export interface BackendClubTaxDeduction {
  allow: boolean;
  signatureUrl?: string;
  textTranslations?: BackendTranslation[];
}

export const toClubTaxDeduction = (backendTaxDeduction: BackendClubTaxDeduction): ClubTaxDeduction => ({
  allow: backendTaxDeduction.allow,
  signatureUrl: Optional.ofUndefinable(backendTaxDeduction.signatureUrl),
  textTranslations: backendTaxDeduction.textTranslations?.map(fromBackendTranslation<ClubTaxDeductionText>),
});
