include ../../club/loading-table.mixin.pug

template(v-if='programsList.isLoading()')
  +loading-table
template(v-else)
  .fp-vertical-spacing(v-if='!hasPrograms' data-test='no-programs')
    .fp-vertical-spacing--line.-align-center
      i.fp-icon.fp-glyph-close-circle.-s50.-color-shade-300
    .fp-vertical-spacing--line.-align-center
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold.-text-lg {{ t('programsPage.empty.title') }}
        .fp-vertical-spacing--line.-align-center {{ t('programsPage.empty.noPrograms') }}
    .fp-vertical-spacing--line.-justify-center
      router-link.fp-link.fp-icon-with-text(:to='{name: "createProgram"}' data-test='create-program')
        i.fp-icon.fp-glyph-plus
        | {{ t('programsPage.empty.create') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='programs-table')
        thead
          tr
            th.-shrink
            th {{ t('programsPage.table.slug') }}
            th {{ t('programsPage.table.name') }}
            th.-shrink
        tbody
          tr(v-for='(program, index) in programsList.value()' :key='index' :data-test='`program-${index}`')
            td(:title='program.name')
              .fp-image.-rounded
                img(:src='program.imageUrl' alt='Program image')
            td(:title='program.name')
              router-link.fp-link(:to='{ name: \'updateProgram\', params: { clubSlug: club.slug, programSlug: program.slug  } }')
                span.fp-text(data-test='slug') {{ program.slug }}
            td {{ program.name }}
            td
              DropdownVue(:name='`actions-program-${program.slug}`')
                DropdownItemVue(:id='program.slug' :text='t(\'programsPage.actions.update\')' icon='edit' :routeLocation='{ name: \'updateProgram\', params: { clubSlug: club.slug, programSlug: program.slug  } }' :data-test='`edit-program-${program.slug}`')
                DropdownSeparatorVue
                DropdownItemVue(:text='t(\'programsPage.actions.see\')' icon='arrow-square-in' :href='`${club.webappUrl}/programs/${program.slug}`' :data-test='`go-to-program-${program.slug}`')
