import { PageVue } from '@/staff/primary/club-layout/page';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { GiveawaysTableVue } from '@/staff/primary/giveaway/giveaways-table';
import { ClubRepository, clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { inject } from 'vue';

export default {
  name: 'GiveawaysPage',

  components: {
    PageVue,
    FairplayerButtonVue,
    GiveawaysTableVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    const clubRepository: ClubRepository = inject(clubRepositoryKey)!;

    const club = clubRepository.getCurrentClub();

    const isGiveaway = !club.referralNeeded;

    return {
      isGiveaway,
      t,
    };
  },
};
