import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { ExclusiveToCreate } from '@/staff/domain/exclusive/ExclusiveToCreate';
import { Url } from '@/common/domain/Url';

import { Language } from '@/common/domain/Language';
import { ExclusiveToUpdate } from '@/staff/domain/exclusive/ExclusiveToUpdate';
import { Optional } from '@/common/domain/Optional';
import { Media } from '@/common/domain/Media';
import { emptyTranslationsUi, fromTranslations, fromTranslationUi, TranslationUi } from '@/common/primary/Translation.ui';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { AuctionLotToCreate } from '@/staff/domain/exclusive/AuctionLotToCreate';
import { AuctionLotToUpdate } from '@/staff/domain/exclusive/AuctionLotToUpdate';
import { fromMediaUi } from '@/common/primary/Media.ui';
import { toArrayToUpdate } from '@/staff/primary/toArrayToUpdate';
import { TaxDeduction } from '@/staff/domain/exclusive/TaxDeduction';
import { Fiat } from '@/common/domain/token/Fiat';
import { ExclusiveToUpdateDeletions } from '@/staff/domain/exclusive/ExclusiveToUpdateDeletions';

export interface ExclusiveFormUi {
  slug: string;
  imageUrl: Url;
  medias: Media[];
  priceCoinsAmount: number;
  visible: boolean;
  stock: number;
  category: ExclusiveCategory;
  isAuction: boolean;
  isTaxDeductible: boolean;
  auctionId?: string;
  auctionLotNumber?: number;
  nonDeductibleAmount?: number;
  nameTranslations: TranslationUi[];
  descriptionTranslations: TranslationUi[];
}

export type ExclusiveTextFormInput = keyof Pick<ExclusiveFormUi, 'imageUrl'>;
export type ExclusiveNumberFormInput = keyof Pick<ExclusiveFormUi, 'stock' | 'priceCoinsAmount'>;

export const toExclusiveToCreate = (exclusiveForm: ExclusiveFormUi, clubSlug: ClubSlug): ExclusiveToCreate => ({
  clubSlug,
  slug: exclusiveForm.slug,
  imageUrl: exclusiveForm.imageUrl,
  medias: exclusiveForm.medias,
  priceCoinsAmount: exclusiveForm.priceCoinsAmount,
  visible: exclusiveForm.visible,
  stock: exclusiveForm.isAuction ? 1 : exclusiveForm.stock,
  category: exclusiveForm.category,
  auctionLot: exclusiveForm.isAuction ? Optional.of(toAuctionLotToCreate(exclusiveForm)) : Optional.empty(),
  taxDeduction: exclusiveForm.isTaxDeductible ? Optional.of(toTaxDeduction(exclusiveForm)) : Optional.empty(),
  nameTranslations: exclusiveForm.nameTranslations.map(fromTranslationUi<ExclusiveName>),
  descriptionTranslations: exclusiveForm.descriptionTranslations.map(fromTranslationUi<ExclusiveDescription>),
});

const toAuctionLotToCreate = (exclusiveForm: ExclusiveFormUi): AuctionLotToCreate => ({
  auctionId: exclusiveForm.auctionId!,
  number: Optional.ofUndefinable(exclusiveForm.auctionLotNumber),
});

const toTaxDeduction = (exclusiveForm: ExclusiveFormUi): TaxDeduction => ({
  nonDeductibleAmount: Fiat.euro(exclusiveForm.nonDeductibleAmount!),
});

export const hasExclusiveFormChanged = (exclusiveForm: ExclusiveFormUi, comparedExclusive: ExclusiveFormUi) =>
  JSON.stringify(exclusiveForm) !== JSON.stringify(comparedExclusive);

export const toExclusiveToUpdate = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive, clubSlug: ClubSlug): ExclusiveToUpdate => ({
  clubSlug: clubSlug,
  currentSlug: exclusive.slug,
  slug: toString(exclusiveForm, exclusive, 'slug'),
  imageUrl: toString(exclusiveForm, exclusive, 'imageUrl'),
  medias: toArrayToUpdate(exclusive.medias, exclusiveForm.medias, fromMediaUi),
  priceCoinsAmount: toNumber(exclusiveForm, exclusive.pricing.coins.amount, 'priceCoinsAmount'),
  visible: toVisible(exclusiveForm, exclusive),
  stock: toNumber(exclusiveForm, exclusive.stock, 'stock'),
  category: toString(exclusiveForm, exclusive, 'category'),
  auctionLot: toAuctionLotToUpdate(exclusiveForm, exclusive),
  taxDeduction: toTaxDeductionToUpdate(exclusiveForm, exclusive),
  nameTranslations: toArrayToUpdate(exclusive.nameTranslations!, exclusiveForm.nameTranslations, fromTranslationUi),
  descriptionTranslations: toArrayToUpdate(exclusive.descriptionTranslations!, exclusiveForm.descriptionTranslations, fromTranslationUi),
  deletions: toDeletions(exclusiveForm, exclusive),
});

const toDeletions = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive): Optional<ExclusiveToUpdateDeletions> => {
  const removedTaxDeduction = exclusive.taxDeduction.isPresent() && !exclusiveForm.isTaxDeductible;

  if (removedTaxDeduction) {
    return Optional.of({ taxDeduction: true });
  }

  return Optional.empty();
};

const toAuctionLotToUpdate = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive): Optional<AuctionLotToUpdate> => {
  if (!exclusiveForm.isAuction) {
    return Optional.empty();
  }
  if (exclusiveForm.auctionLotNumber === exclusive.auctionLot.flatMap(lot => lot.number).orElseUndefined()) {
    return Optional.empty();
  }
  return Optional.of({
    number: Optional.of(exclusiveForm.auctionLotNumber!),
  });
};

const toTaxDeductionToUpdate = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive): Optional<TaxDeduction> => {
  if (!exclusiveForm.isTaxDeductible) {
    return Optional.empty();
  }
  if (
    exclusiveForm.nonDeductibleAmount === exclusive.taxDeduction.map(deduction => deduction.nonDeductibleAmount.value).orElseUndefined()
  ) {
    return Optional.empty();
  }
  return Optional.of({
    nonDeductibleAmount: Fiat.euro(exclusiveForm.nonDeductibleAmount!),
  });
};

type UpdatableExclusiveStringField = keyof Pick<ExclusiveFormUi, 'slug' | 'imageUrl' | 'category'>;
type UpdatableExclusiveNumberField = keyof Pick<ExclusiveFormUi, 'priceCoinsAmount' | 'stock'>;

const toString = <T>(exclusiveForm: ExclusiveFormUi, exclusive: Exclusive, field: UpdatableExclusiveStringField): Optional<T> =>
  exclusiveForm[field].localeCompare(exclusive[field]) !== 0 ? Optional.of<T>(exclusiveForm[field] as T) : Optional.empty();

const toNumber = (exclusiveForm: ExclusiveFormUi, numberToCompare: number, field: UpdatableExclusiveNumberField): Optional<number> =>
  Optional.of(exclusiveForm[field]).filter(updatableField => updatableField != numberToCompare);

const toVisible = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive): Optional<boolean> =>
  Optional.of(exclusiveForm.visible).filter(visible => visible != exclusive.visible);

export const fromExclusive = (exclusive: Exclusive, languages: Language[]): ExclusiveFormUi => ({
  slug: exclusive.slug,
  imageUrl: exclusive.imageUrl,
  medias: exclusive.medias,
  priceCoinsAmount: exclusive.pricing.coins.amount,
  visible: exclusive.visible,
  stock: exclusive.stock,
  category: exclusive.category,
  isAuction: exclusive.auctionLot.isPresent(),
  auctionId: exclusive.auctionLot.map(lot => lot.auction.id).orElseUndefined(),
  auctionLotNumber: exclusive.auctionLot.flatMap(lot => lot.number).orElseUndefined(),
  isTaxDeductible: exclusive.taxDeduction.isPresent(),
  nonDeductibleAmount: exclusive.taxDeduction
    .map(deduction => deduction.nonDeductibleAmount)
    .map(fiat => fiat.value)
    .orElse(0),
  nameTranslations: fromTranslations(languages, exclusive.nameTranslations!),
  descriptionTranslations: fromTranslations(languages, exclusive.descriptionTranslations!),
});

export const emptyExclusiveForm = (languages: string[]): ExclusiveFormUi => ({
  slug: '',
  imageUrl: '',
  medias: [],
  priceCoinsAmount: 0,
  visible: true,
  stock: 1,
  category: ExclusiveCategory.COLLECTIBLE,
  isAuction: false,
  isTaxDeductible: false,
  nonDeductibleAmount: 0,
  nameTranslations: emptyTranslationsUi(languages),
  descriptionTranslations: emptyTranslationsUi(languages),
});
