include ../../../../common/primary/select/select.mixin.pug

form.fp-vertical-spacing(autocomplete='on' @submit.prevent='confirm' @dragover.prevent='onDragOver' @drop.prevent='onDrop' data-test='club-form')
  .fp-vertical-spacing--line
    .fp-card.-highlighted(@click='openClubPreviewSidebar' data-test='global-infos')
      .fp-vertical-spacing
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-info.-s16
            .fp-horizontal-spacing--column
              .fp-text.-text-semi-bold.-text-lg {{ t('clubForm.globalInformation') }}
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-equivalent
            .fp-horizontal-spacing--column(:draggable='true' @dragstart='onDragStart' data-test='draggable')
              label.fp-field
                | {{ t('clubForm.name') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("name")' :class='hasFieldChanged("name") ? "-pointer" : "-color-shade-400"' data-test='reset-name' :title='t(\'undo\')')
                input.fp-input-text(v-model='clubForm.name' type='text' required :class='{"-touched": isUpdating && hasFieldChanged("name")}' data-test='name')
            .fp-horizontal-spacing--column
              label.fp-field
                | {{ t('clubForm.platformName') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("platformName")' :class='hasFieldChanged("platformName") ? "-pointer" : "-color-shade-400"' data-test='reset-platform-name' :title='t(\'undo\')')
                input.fp-input-text(v-model='clubForm.platformName' type='text' required :class='{"-touched": isUpdating && hasFieldChanged("platformName")}' data-test='platform-name')
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-equivalent
            .fp-horizontal-spacing--column(data-test='logo')
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  .fp-horizontal-spacing
                    .fp-horizontal-spacing--column.-expand
                      label.fp-field {{ t('clubForm.logo') }}
                    .fp-horizontal-spacing--column
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("logoUrl")' :class='hasFieldChanged("logoUrl") ? "-pointer" : "-color-shade-400"' data-test='reset-logo-url' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  SingleImageInputVue(:media='logoImage' :touched='isUpdating && hasFieldChanged("logoUrl")' :required='!isUpdating' @updated-media='media => updateLogo(media.value)')
            .fp-horizontal-spacing--column(data-test='banner')
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  .fp-horizontal-spacing
                    .fp-horizontal-spacing--column.-expand
                      label.fp-field {{ t('clubForm.banner') }}
                    .fp-horizontal-spacing--column
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("bannerUrl")' :class='hasFieldChanged("bannerUrl") ? "-pointer" : "-color-shade-400"' data-test='reset-banner-url' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  SingleImageInputVue(:media='bannerImage' :touched='isUpdating && hasFieldChanged("bannerUrl")' :required='!isUpdating' @updated-media='media => updateBanner(media.value)')
        .fp-vertical-spacing--line(v-for='presentationTranslation in clubForm.presentationTranslations' :key='presentationTranslation.language')
          .fp-horizontal-spacing.-equivalent
            .fp-horizontal-spacing--column
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  label.fp-field
                    .fp-horizontal-spacing.-items-center.-s-sm
                      .fp-horizontal-spacing--column
                        | {{ t('clubForm.presentation') }}
                      .fp-horizontal-spacing--column
                        img.fp-illustration.-xs(:class='`-flag-${presentationTranslation.language}`' :data-test='`presentation-flag-${presentationTranslation.language}`')
                      .fp-field--actions
                        template(v-if='presentationTranslation.language === "en"')
                          i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                          i.fp-icon.fp-glyph-translate(v-else @click='translatePresentation(presentationTranslation.language)' :class='hasPresentation ? "-pointer" : "-color-shade-400"' :title='t(\'clubForm.translate\')' :data-test='`translate-presentation-${presentationTranslation.language}`')
                        i.fp-icon.-pointer(@click='togglePresentationPreviewFor(presentationTranslation)' :class='previewVisibleFor(presentationTranslation) ? "fp-glyph-eye-slash" : "fp-glyph-eye"' :data-test='`toggle-preview-${presentationTranslation.language}`' :title='previewVisibleFor(presentationTranslation) ? t("hidePreview") : t("showPreview")')
                        i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetPresentationTranslation(presentationTranslation.language)' :class='hasPresentationTranslationChanged(presentationTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-presentation-${presentationTranslation.language}`' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  MarkdownEditorVue(:text='presentationTranslation' @updated-value='text => updateTranslation(presentationTranslation, text.value)' @focus='onInputFocus(presentationTranslation.language)' :touched='isUpdating && hasPresentationTranslationChanged(presentationTranslation)' :data-test='`presentation-${presentationTranslation.language}`' :disabled='isTranslationLoading')
            .fp-horizontal-spacing--column(v-if='previewVisibleFor(presentationTranslation)')
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  .fp-text.-text-semi-bold {{ t('clubForm.preview') }}
                .fp-vertical-spacing--line
                  .fp-text.-text-sm(v-html='formatMarkdown(presentationTranslation.value)' :data-test='`preview-presentation-${presentationTranslation.language}`')
  .fp-vertical-spacing--line
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-xl
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-mail.-s16
            .fp-horizontal-spacing--column
              .fp-text.-text-semi-bold.-text-lg {{ t('clubForm.mailing') }}
        .fp-vertical-spacing--line
          .fp-vertical-spacing
            .fp-vertical-spacing--line
              label.fp-field
                | {{ t('clubForm.contactEmail') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("contactEmail")' :class='hasFieldChanged("contactEmail") ? "-pointer" : "-color-shade-400"' data-test='reset-contact-email' :title='t(\'undo\')')
                input.fp-input-text(v-model='clubForm.contactEmail' type='email' required :class='{"-touched": isUpdating && hasFieldChanged("contactEmail")}' data-test='contact-email')
            .fp-vertical-spacing--line
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  label.fp-field {{ t('clubForm.recipientEmails') }}
                .fp-vertical-spacing--line
                  .fp-vertical-spacing.-s-sm
                    .fp-vertical-spacing--line(v-for='(recipient, recipientIndex) in clubForm.recipientEmails' :key='`recipient-email-${recipientIndex}`' data-test='recipient-emails')
                      .fp-horizontal-spacing.-items-center.-s-sm
                        .fp-horizontal-spacing--column.-expand
                          input.fp-input-text(v-model='clubForm.recipientEmails[recipientIndex]' type='email' required :class='{"-touched": isUpdating && hasRecipientEmailChanged(recipientIndex)}' :data-test='`recipient-email-${recipientIndex}`')
                        .fp-horizontal-spacing--column
                          button.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetRecipientEmail(recipientIndex)' :class='hasRecipientEmailChanged(recipientIndex) ? "-pointer" : "-color-shade-400"' :data-test='`reset-recipient-email-${recipientIndex}`' :title='t(\'undo\')' type='button')
                        .fp-horizontal-spacing--column
                          button.fp-icon.fp-glyph-trash.-s16(:data-test='`remove-recipient-email-${recipientIndex}`' :disabled='clubForm.recipientEmails.length === 1' :class='clubForm.recipientEmails.length === 1 ? "-color-shade-400" : "-pointer"' :title='t(\'giveawayForm.removeAnswer\')' type='button' @click='removeRecipientEmail(recipientIndex)')
                    .fp-vertical-spacing--line
                      a.fp-link(data-test='add-recipient-email' @click='addRecipientEmail')
                        span.fp-link--icon
                          i.fp-icon.fp-glyph-plus.-s16
                        | {{ t('clubForm.addRecipient') }}
  .fp-vertical-spacing--line(v-if='clubForm.isFoundation')
    .fp-card.-highlighted(@click='openTaxDeductionEmailPreviewSidebar' data-test='tax-deduction')
      .fp-vertical-spacing
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-edit.-s16
            .fp-horizontal-spacing--column
              .fp-text.-text-semi-bold.-text-lg {{ t('clubForm.taxDeductionTitle') }}
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-equivalent
            .fp-horizontal-spacing--column
              .fp-vertical-spacing
                .fp-vertical-spacing--line
                  .fp-vertical-spacing.-s-sm
                    .fp-vertical-spacing--line
                      label.fp-field
                        | {{ t('clubForm.taxDeduction.availability') }}
                        .fp-field--actions
                          i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTaxDeductionAllowField' :class='hasTaxDeductionFieldChanged("allow") ? "-pointer" : "-color-shade-400"' data-test='reset-allow' :title='t(\'undo\')')
                    .fp-vertical-spacing--line
                      .fp-checkable-element
                        .fp-checkable-element--checkbox
                          input.fp-checkbox#allow-checkbox(type='checkbox' v-model='clubForm.taxDeduction.allow' :class='{"-touched": isUpdating && hasTaxDeductionFieldChanged("allow")}' data-test='allow')
                        .fp-checkable-element--label
                          label(for='allow-checkbox') {{ t('clubForm.taxDeduction.allow') }}
                .fp-vertical-spacing--line
                  .fp-horizontal-spacing--column(data-test='signature')
                    .fp-vertical-spacing.-s-xs
                      .fp-vertical-spacing--line
                        .fp-horizontal-spacing
                          .fp-horizontal-spacing--column.-expand
                            label.fp-field {{ t('clubForm.taxDeduction.signature') }}
                          .fp-horizontal-spacing--column
                            i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTaxDeductionSignatureUrl' :class='hasTaxDeductionFieldChanged("signatureUrl") ? "-pointer" : "-color-shade-400"' data-test='reset-signature-url' :title='t(\'undo\')')
                      .fp-vertical-spacing--line
                        SingleImageInputVue(:media='signatureImage' :touched='isUpdating && hasTaxDeductionFieldChanged("signatureUrl")' :required='!isUpdating' @updated-media='media => updateSignatureUrl(media.value)')
            .fp-horizontal-spacing--column
              .fp-vertical-spacing
                .fp-vertical-spacing--line(v-for='textTranslation in clubForm.taxDeduction.textTranslations' :key='textTranslation.language')
                  .fp-vertical-spacing.-s-sm
                    .fp-vertical-spacing--line
                      label.fp-field
                        .fp-horizontal-spacing.-items-center.-s-sm
                          .fp-horizontal-spacing--column
                            | {{ t('clubForm.taxDeduction.text') }}
                          .fp-horizontal-spacing--column
                            img.fp-illustration.-xs(:class='`-flag-${textTranslation.language}`' :data-test='`tax-deduction-text-flag-${textTranslation.language}`')
                          .fp-field--actions
                            template(v-if='textTranslation.language === "en"')
                              i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                              i.fp-icon.fp-glyph-translate(v-else @click='translateTaxDeductionText(textTranslation.language)' :class='hasTaxDeductionText ? "-pointer" : "-color-shade-400"' :title='t(\'clubForm.translate\')' :data-test='`translate-tax-deduction-text-${textTranslation.language}`')
                            i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTaxDeductionTextTranslation(textTranslation.language)' :class='hasTaxDeductionTextTranslationChanged(textTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-tax-deduction-text-${textTranslation.language}`' :title='t(\'undo\')')
                    .fp-vertical-spacing--line
                      textarea.fp-input-text(rows='8' v-model='textTranslation.value' :class='{"-touched": isUpdating && hasTaxDeductionTextTranslationChanged(textTranslation)}' :disabled='isTranslationLoading' @focus='onInputFocus(textTranslation.language)' :data-test='`tax-deduction-text-${textTranslation.language}`')
