import { GiveawayAnswer } from '@/staff/domain/club/giveaway/GiveawayAnswer';

export interface GiveawayAnswerUi {
  id?: string;
  text: string;
}

export const fromGiveawayAnswer = (giveawayAnswer: GiveawayAnswer): GiveawayAnswerUi => ({
  id: giveawayAnswer.id,
  text: giveawayAnswer.text,
});
