import { Language } from '@/common/domain/Language';
import { Translation } from '@/common/domain/Translation';
import { TranslatableField } from '@/common/domain/TranslatableField';
import { Optional } from '@/common/domain/Optional';

export interface TranslationUi {
  language: Language;
  value: string;
}

export const toTranslationUi = <T extends TranslatableField>(translation: Translation<T>): TranslationUi => ({
  language: translation.language,
  value: translation.value,
});

export const fromTranslationUi = <T extends TranslatableField>(translation: TranslationUi): Translation<T> => ({
  language: translation.language,
  value: translation.value as T,
});

export const translationFor = (translations: TranslationUi[], language: string): TranslationUi =>
  translations.find(t => t.language === language)!;

const emptyTranslation = (language: Language): TranslationUi => ({ language, value: '' });

const existingTranslationOrEmpty =
  <T extends TranslatableField>(translations: Translation<T>[]) =>
  (language: Language) =>
    translations.find(translation => translation.language === language) || emptyTranslation(language);

const byLanguageCodeDesc = () => (language1: Language, language2: Language) => language2.localeCompare(language1);

export const fromTranslations = <T extends TranslatableField>(languages: Language[], translations: Translation<T>[]) =>
  languages.sort(byLanguageCodeDesc()).map(existingTranslationOrEmpty(translations)).map(toTranslationUi);

export const emptyTranslationsUi = (languages: string[]): TranslationUi[] =>
  languages.sort(byLanguageCodeDesc()).map(emptyTranslation).map(toTranslationUi);

const stringify = (uiTranslations: TranslationUi[]) => JSON.stringify(uiTranslations.filter(t => t.value));

export const toUpdatedTranslations = <T extends TranslatableField>(
  oldTranslations: Translation<T>[],
  uiTranslations: TranslationUi[]
): Optional<Translation<T>[]> =>
  stringify(oldTranslations.map(toTranslationUi)) !== stringify(uiTranslations)
    ? Optional.of(uiTranslations.map(fromTranslationUi<T>).filter(t => t.value))
    : Optional.empty();
