import { computed, ComputedRef, defineComponent, inject, onMounted, onUnmounted, PropType, ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { ClubFormUi, ClubTextFormInput, hasClubFormChanged } from '@/staff/primary/club/club-form/ClubForm.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { Language } from '@/common/domain/Language';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { translationFor, TranslationUi } from '@/common/primary/Translation.ui';
import { dragOver, dragStart, drop } from '@/common/primary/DragDrop';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { clubPreviewSidebar, taxDeductionEmailPreviewSidebar } from '@/common/primary/sidebar/Sidebars';
import { ClubPreviewSidebarOptions } from '@/staff/primary/club/club-preview-sidebar/ClubPreviewSidebarOptions';
import { SingleImageInputVue } from '@/staff/primary/media-input/single-image-input';
import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';
import { ClubTaxDeductionFormUi } from '@/staff/primary/club/club-form/ClubTaxDeductionForm.ui';
import { stafferLanguageRepositoryKey } from '@/common/domain/StafferLanguageRepository';
import { TaxDeductionEmailPreviewSidebarOptions } from '@/staff/primary/club/tax-deduction-email-preview-sidebar/TaxDeductionEmailPreviewSidebarOptions';

export default defineComponent({
  name: 'ClubForm',

  components: { MarkdownEditorVue, SingleImageInputVue },

  props: {
    slug: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formValue: {
      type: Object as PropType<ClubFormUi>,
      required: true,
    },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const sidebarBus = inject(sidebarBusKey)!;
    const stafferLanguageRepository = inject(stafferLanguageRepositoryKey)!;

    const clubForm = ref<ClubFormUi>(structuredClone(toRaw(props.formValue)));
    const askedCreate = ref<boolean>(false);
    const focusedLanguage = ref<Language>(stafferLanguageRepository.getCurrentLanguage());
    const isTranslationLoading = ref<boolean>(false);
    const hiddenPreviews = ref<Record<string, boolean>>({});
    const previewVisibleFor = (translation: TranslationUi) => !hiddenPreviews.value[translation.language];
    const hasPresentation = computed(() => clubForm.value.presentationTranslations[0].value.length > 0);
    const hasTaxDeductionText = computed(() => clubForm.value.taxDeduction.textTranslations[0].value.length > 0);

    const formatMarkdown = (text: string) => renderMarkdown(text) as string;

    const logoImage = computed((): Media | undefined => {
      if (clubForm.value.logoUrl === '') {
        return undefined;
      }
      return {
        url: clubForm.value.logoUrl,
        type: MediaType.IMAGE,
      };
    });

    const signatureImage = computed((): Media | undefined => {
      if (!clubForm.value.taxDeduction.signatureUrl) {
        return undefined;
      }
      return {
        url: clubForm.value.taxDeduction.signatureUrl,
        type: MediaType.IMAGE,
      };
    });

    const bannerImage = computed((): Media | undefined => {
      if (clubForm.value.bannerUrl === '') {
        return undefined;
      }
      return {
        url: clubForm.value.bannerUrl,
        type: MediaType.IMAGE,
      };
    });

    const hasFormChanged = () => computed(() => hasClubFormChanged(clubForm.value as ClubFormUi, props.formValue));

    const hasFieldChanged = (field: keyof ClubFormUi) => clubForm.value[field] !== props.formValue[field];

    const hasTaxDeductionFieldChanged = (field: keyof ClubTaxDeductionFormUi) =>
      clubForm.value.taxDeduction[field] !== props.formValue.taxDeduction[field];

    const hasRecipientEmailChanged = (index: number) => clubForm.value.recipientEmails[index] !== props.formValue.recipientEmails[index];

    const resetTextField = (field: ClubTextFormInput) => {
      clubForm.value[field] = props.formValue[field];
    };

    const resetTaxDeductionSignatureUrl = () => {
      clubForm.value.taxDeduction.signatureUrl = props.formValue.taxDeduction.signatureUrl;
    };

    const resetRecipientEmail = (index: number) => {
      if (props.formValue.recipientEmails.length < index + 1) {
        removeRecipientEmail(index);
      } else {
        clubForm.value.recipientEmails[index] = props.formValue.recipientEmails[index];
      }
    };

    const openClubPreviewSidebar = () => {
      const sidebar = clubPreviewSidebar();
      sidebarBus.open<ClubPreviewSidebarOptions>({
        title: t('clubForm.previewSidebar.title'),
        component: sidebar,
        isClosable: true,
        options: {
          clubForm: clubForm.value,
          focusedLanguage: focusedLanguage.value,
        },
      });
    };

    const openTaxDeductionEmailPreviewSidebar = () => {
      const sidebar = taxDeductionEmailPreviewSidebar();
      sidebarBus.open<TaxDeductionEmailPreviewSidebarOptions>({
        title: t('clubForm.taxDeductionEmailPreviewSidebar.title'),
        component: sidebar,
        isClosable: true,
        options: {
          clubForm: clubForm.value,
          focusedLanguage: focusedLanguage.value,
        },
      });
    };

    const onInputFocus = (language: Language): void => {
      focusedLanguage.value = language;
    };

    onMounted(async () => {
      globalWindow.onbeforeunload = () => {
        if (hasFormChanged().value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };
    });

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    const routeLeaveGuard = () => {
      if (hasFormChanged().value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const addRecipientEmail = () => clubForm.value.recipientEmails.push('');

    const confirm = () => {
      if (!props.isUpdating) {
        askedCreate.value = true;
      }
      emit('confirm', clubForm.value);
    };

    const resetPresentationTranslation = (language: Language) => {
      translationFor(clubForm.value.presentationTranslations, language).value = translationFor(
        props.formValue.presentationTranslations,
        language
      ).value;
    };
    const resetTaxDeductionTextTranslation = (language: Language) => {
      translationFor(clubForm.value.taxDeduction.textTranslations, language).value = translationFor(
        props.formValue.taxDeduction.textTranslations,
        language
      ).value;
    };

    const resetTaxDeductionAllowField = () => {
      clubForm.value.taxDeduction.allow = props.formValue.taxDeduction.allow;
    };

    const hasPresentationTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.presentationTranslations, translation.language).value;

    const hasTaxDeductionTextTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.taxDeduction.textTranslations, translation.language).value;

    const togglePresentationPreviewFor = (translation: TranslationUi) => {
      hiddenPreviews.value[translation.language] = !hiddenPreviews.value[translation.language];
    };

    const updateTranslation = (translation: TranslationUi, updatedPresentation: string) => {
      translation.value = updatedPresentation;
    };

    const translateText = async (targetLanguage: string, translations: TranslationUi[], hasTranslation: ComputedRef<boolean>) => {
      if (hasTranslation.value && !isTranslationLoading.value) {
        isTranslationLoading.value = true;
        const frenchTranslation = translationFor(translations, 'fr');
        const translation = await clubRepository.translateText({
          text: frenchTranslation.value,
          sourceLanguage: 'fr',
          targetLanguage,
          clubSlug: props.slug,
        });
        const index = translations.findIndex(translation => translation.language === targetLanguage);
        translations.splice(index, 1, {
          ...translations[index],
          value: translation,
        });
        isTranslationLoading.value = false;
      }
    };

    const removeRecipientEmail = (recipientIndex: number): void => {
      clubForm.value = { ...clubForm.value, recipientEmails: clubForm.value.recipientEmails.filter((_, idx) => idx !== recipientIndex) };
    };

    const translatePresentation = (targetLanguage: string) =>
      translateText(targetLanguage, clubForm.value.presentationTranslations, hasPresentation);

    const translateTaxDeductionText = (targetLanguage: string) =>
      translateText(targetLanguage, clubForm.value.taxDeduction.textTranslations, hasTaxDeductionText);

    const onDragStart = (event: DragEvent) => dragStart(event, clubForm.value);

    const onDragOver = async (event: DragEvent) => dragOver(event);

    const onDrop = async (event: DragEvent) => {
      drop<ClubFormUi>(event).ifPresent(value => (clubForm.value = value));
    };
    const updateLogo = (media: Media) => (clubForm.value.logoUrl = media.url);

    const updateSignatureUrl = (media: Media) => (clubForm.value.taxDeduction.signatureUrl = media.url);

    const updateBanner = (media: Media) => (clubForm.value.bannerUrl = media.url);

    return {
      t,
      hasFieldChanged,
      hasTaxDeductionFieldChanged,
      hasRecipientEmailChanged,
      resetTextField,
      resetRecipientEmail,
      resetTaxDeductionAllowField,
      resetTaxDeductionSignatureUrl,
      confirm,
      clubForm,
      logoImage,
      signatureImage,
      bannerImage,
      translateText,
      onDragStart,
      onDragOver,
      onDrop,
      hasPresentation,
      hasTaxDeductionText,
      translatePresentation,
      translateTaxDeductionText,
      isTranslationLoading,
      openClubPreviewSidebar,
      openTaxDeductionEmailPreviewSidebar,
      onInputFocus,
      focusedLanguage,
      routeLeaveGuard,
      togglePresentationPreviewFor,
      updateTranslation,
      updateLogo,
      updateSignatureUrl,
      updateBanner,
      previewVisibleFor,
      formatMarkdown,
      resetPresentationTranslation,
      resetTaxDeductionTextTranslation,
      hasPresentationTranslationChanged,
      hasTaxDeductionTextTranslationChanged,
      addRecipientEmail,
      removeRecipientEmail,
    };
  },
});
