import { Exclusives } from '@/staff/domain/exclusive/Exclusives';
import { ExclusiveUi, fromExclusive } from '@/staff/primary/exclusive/Exclusive.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface ExclusivesUi {
  exclusives: ExclusiveUi[];
  totalTokens: TokensUi;
  visibleTotalTokens: TokensUi;
}

export const fromExclusives = (exclusives: Exclusives): ExclusivesUi => ({
  exclusives: exclusives.exclusives.map(fromExclusive),
  totalTokens: fromTokens(exclusives.totalTokens()),
  visibleTotalTokens: fromTokens(exclusives.visibleTotalTokens()),
});

export const emptyExclusives = (): ExclusivesUi => ({
  exclusives: [],
  totalTokens: emptyTokensUi(),
  visibleTotalTokens: emptyTokensUi(),
});

export const filterExclusives = (exclusiveUis: ExclusiveUi[], showNotVisibleExclusives: boolean, searchingTerm: string) => {
  let exclusivesList = exclusiveUis;
  if (!showNotVisibleExclusives) {
    exclusivesList = exclusivesList.filter(exclusive => exclusive.visible);
  }
  if (searchingTerm) {
    const termsLowered = searchingTerm.toLowerCase();
    exclusivesList = exclusivesList.filter(
      exclusive => exclusive.slug.includes(termsLowered) || exclusive.name.toLowerCase().includes(termsLowered)
    );
  }
  return exclusivesList;
};
