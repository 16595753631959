PageVue
  .fp-vertical-spacing.-s-xl
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='update-club.back' @click='onGoBack')
        .fp-horizontal-spacing--column.-expand
          h2.fp-title {{ t('clubForm.updateTitle') }}
        .fp-horizontal-spacing--column
          router-link.fp-link.-no-text-decoration(:to='{ name: \'paymentPartner\', params: { clubSlug }}' data-test='payment-partner')
            FairplayerButtonVue(:text='t(\'clubForm.paymentPartner\')' secondary icon='wallet')
    .fp-vertical-spacing--line(v-if='!clubForm.isLoading()')
      ClubFormVue#update-club-form(:slug='clubSlug' :is-updating='true' :form-value='clubForm.value()' @confirm='onConfirmUpdate')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='update-club.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'clubForm.updateClub\')' :loading='updating' type='submit' form='update-club-form' icon='edit' data-test='update-club.submit')
