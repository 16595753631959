PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='update-post.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('postForm.updateTitle') }}
    .fp-vertical-spacing--line(v-if='!postForm.isLoading()')
      PostFormVue#update-post-form(:club-slug='clubSlug' :is-updating='true' :form-value='postForm.value()' @confirm='onConfirmUpdate')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='update-post.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'postForm.updatePost\')' :loading='updating' type='submit' form='update-post-form' icon='edit' data-test='update-post.submit')
