include ../loading-table.mixin.pug

.fp-vertical-spacing--line(v-if='coinsGifts.isLoading()')
  +loading-table
template(v-else)
  .fp-vertical-spacing--line
    .fp-horizontal-spacing.-s-sm.-items-center
      .fp-horizontal-spacing--column
        i.fp-icon.fp-glyph-award.-s18
      .fp-horizontal-spacing--column.-expand
        h2.fp-title.-color-shade-700 {{ t('coinsGifts.title') }}
      .fp-horizontal-spacing--column
        FairplayerButtonVue(icon='plus' :text='t(\'coinsGifts.giveCoins\')' @click='giveCoins' data-test='give-coins')
  .fp-vertical-spacing--line
    .fp-vertical-spacing(v-if='!hasCoinsGifts' data-test='no-coins-gifts')
      .fp-vertical-spacing--line.-align-center
        i.fp-icon.fp-glyph-close-circle.-s50.-color-shade-300
      .fp-vertical-spacing--line.-align-center
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line
            .fp-text.-text-semi-bold.-text-lg {{ t('coinsGifts.empty.title') }}
          .fp-vertical-spacing--line.-align-center {{ t('coinsGifts.empty.noCoinsGifts') }}
      .fp-vertical-spacing--line.-justify-center
        a.fp-link.fp-icon-with-text(@click='giveCoins' data-test='give-coins')
          i.fp-icon.fp-glyph-plus
          span {{ t('coinsGifts.empty.create') }}
    .fp-vertical-spacing(v-else)
      .fp-vertical-spacing--line
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-checkbox#expired-checkbox(v-model='showExpiredCoinsGifts' type='checkbox' data-test='expired-checkbox')
          .fp-checkable-element--label
            label(for='expired-checkbox') {{ t('coinsGifts.showExpiredCoinsGifts') }}
      .fp-vertical-spacing--line
        p(v-if='!filteredCoinsGifts.length' data-test='no-filtered-coins-gifts') {{ t('coinsGifts.noResults') }}
        table.fp-table(data-test='coins-gifts-table')
          thead
            tr
              th.-shrink
              th {{ t('home.table.date') }}
              th {{ t('coinsGifts.table.fan') }}
              th {{ t('coinsGifts.table.totalCost') }}
              th(v-if='club.coinsEnabled' data-test='tokens-amount') {{ t('coinsGifts.table.tokensAmount') }}
          tbody
            tr(v-for='(coinsGift, index) in filteredCoinsGifts' :key='index' :data-test='`coins-gift-${index}`')
              td
                i.fp-icon.-s18(:class='coinsGift.iconClass' :title='t(`coinsGifts.table.title.${coinsGift.status}`)' data-test='status-icon')
              td(:title='coinsGift.date.toHuman()')
                RelativeTimeVue(:date='coinsGift.date.date')
              td {{ coinsGift.fanEmail }}
              td {{ coinsGift.amount.totalCost.text }}
              td(v-if='club.coinsEnabled') {{ coinsGift.amount.coins.text }}
      .fp-vertical-spacing--line
        .fp-alert.-info
          i.fp-icon.fp-glyph-info.-color-info.-s18
          ul
            li
              span.fp-text {{ t('coinsGifts.totalAmountNotExpired') }} &nbsp;
              ExclusivePriceVue(:pricing='coinsGifts.value().totalNotExpiredTokens' data-test='total-coins-gifts-not-expired')
            li
              span.fp-text {{ t('coinsGifts.totalAmount') }} &nbsp;
              ExclusivePriceVue(:pricing='coinsGifts.value().totalTokens' data-test='total-coins-gifts')
