import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import {
  ExclusiveFormUi,
  ExclusiveNumberFormInput,
  ExclusiveTextFormInput,
  hasExclusiveFormChanged,
} from '@/staff/primary/exclusive/exclusive-form/ExclusiveForm.ui';
import { computed, ComputedRef, defineComponent, inject, onMounted, onUnmounted, PropType, ref, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { globalWindowKey } from '@/common/domain/Window';
import { FairplayerButtonVue } from '@/common/primary/button';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { ConverterVue } from '@/common/primary/converter';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { Language } from '@/common/domain/Language';
import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';
import { MultipleMediasInputVue } from '@/staff/primary/media-input/multiple-medias-input';
import { SingleImageInputVue } from '@/staff/primary/media-input/single-image-input';
import { translationFor, TranslationUi } from '@/common/primary/Translation.ui';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { createDraftDescriptionSidebar } from '@/common/primary/sidebar/Sidebars';
import { AuctionUi, fromAuction } from '@/staff/primary/exclusive/Auction.ui';
import { auctionRepositoryKey } from '@/staff/domain/exclusive/AuctionRepository';
import { Loader } from '@/common/primary/loader/Loader';
import { dragOver, dragStart, drop } from '@/common/primary/DragDrop';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';
import { DraftDescriptionSidebarOptions } from '@/staff/primary/completion/draft-description-sidebar/DraftDescriptionSidebarOptions';
import { fromFiat } from '@/common/primary/token/Fiat.ui';
import { Fiat } from '@/common/domain/token/Fiat';
import { ExclusiveSlug } from '@/staff/domain/exclusive/ExclusiveSlug';

const exclusiveNameForSlugMatcher = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

export default defineComponent({
  name: 'ExclusiveForm',

  components: { ConverterVue, SingleImageInputVue, MultipleMediasInputVue, FairplayerButtonVue, MarkdownEditorVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formValue: {
      type: Object as PropType<ExclusiveFormUi>,
      required: true,
    },
    tokensPrice: {
      type: Number,
      required: true,
    },
    limitedUpdate: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const auctionRepository = inject(auctionRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const sidebarBus = inject(sidebarBusKey)!;

    const club = clubRepository.getCurrentClub();
    const categories = Object.keys(ExclusiveCategory);

    const auctions = ref(Loader.loading<AuctionUi[]>());
    const exclusivesSlugs = ref(Loader.loading<ExclusiveSlug[]>());
    const exclusiveForm = ref<ExclusiveFormUi>(structuredClone(toRaw(props.formValue)));
    const isTranslationLoading = ref<boolean>(false);
    const askedCreate = ref<boolean>(false);
    const draftDescription = ref<string>('');
    const hiddenPreviews = ref<Record<string, boolean>>({});
    const previewVisibleFor = (translation: TranslationUi) => !hiddenPreviews.value[translation.language];
    const isEditIconShown = ref<boolean>(false);
    const hasName = computed(() => exclusiveForm.value.nameTranslations[0].value.length > 0);
    const hasDescription = computed(() => exclusiveForm.value.descriptionTranslations[0].value.length > 0);
    const slugAlreadyExists = computed(
      () =>
        !exclusivesSlugs.value.isLoading() && hasFieldChanged('slug') && exclusivesSlugs.value.value().includes(exclusiveForm.value.slug)
    );
    const useLotNumbers = computed(
      () =>
        exclusiveForm.value.auctionId &&
        auctions.value.value().find(auction => exclusiveForm.value.auctionId! === auction.id)!.useLotNumbers
    );

    const price = computed(() => exclusiveForm.value.priceCoinsAmount * club.coin.price);
    const priceSimulation = ref(price.value);
    const priceSimulationText = computed(() => fromFiat(Fiat.euro(+priceSimulation.value)).text);
    const priceSimulationMin = computed(() => exclusiveForm.value.priceCoinsAmount * club.coin.price);
    const priceSimulationMax = computed(() => priceSimulationMin.value * 3);
    const priceSimulationStep = computed(() => (priceSimulationMax.value - priceSimulationMin.value) / 20);
    const priceSimulationNonDeductible = computed(() => {
      const deductibleAmount = exclusiveForm.value.isAuction ? priceSimulation.value : price.value;
      return fromFiat(Fiat.euro(Math.max(deductibleAmount - exclusiveForm.value.nonDeductibleAmount!, 0)));
    });

    const formatDescription = (description: string) => renderMarkdown(description) as string;

    const hasFormChanged = computed(() => hasExclusiveFormChanged(exclusiveForm.value as ExclusiveFormUi, props.formValue));

    const taxDeductionCoinsAmount = computed(() => exclusiveForm.value.nonDeductibleAmount! / club.coin.price);

    const mainImage = computed((): Media | undefined => {
      if (exclusiveForm.value.imageUrl === '') {
        return undefined;
      }
      return {
        url: exclusiveForm.value.imageUrl,
        type: MediaType.IMAGE,
      };
    });

    const listAuctions = () =>
      auctionRepository.listByClub(club.slug).then(retrievedAuctions => auctions.value.loaded(retrievedAuctions.map(fromAuction)));

    const listExclusivesSlugs = () =>
      exclusiveRepository
        .getByClub(club.slug, club.coin)
        .then(retrievedExclusives => exclusivesSlugs.value.loaded(retrievedExclusives.exclusives.map(exclusive => exclusive.slug)));

    onMounted(() => {
      listAuctions();
      listExclusivesSlugs();
      globalWindow.onbeforeunload = () => {
        if (hasFormChanged.value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };
    });

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    watch(
      () => [props.formValue],
      () => {
        exclusiveForm.value = structuredClone(toRaw(props.formValue));
      }
    );

    const routeLeaveGuard = () => {
      if (hasFormChanged.value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const confirm = () => {
      if (exclusiveForm.value.descriptionTranslations.some(translation => !translation.value?.trim())) {
        globalWindow.alert(t('exclusiveForm.blankDescription'));
        return;
      }

      if (slugAlreadyExists.value) {
        globalWindow.alert(t('exclusiveForm.slugAlreadyExists'));
        return;
      }

      if (!props.isUpdating) {
        askedCreate.value = true;
      }
      emit('confirm', exclusiveForm.value);
    };

    const updateDraft = (description: string) => {
      draftDescription.value = description;
      replaceDescriptionWithDraft('fr');
    };

    const requestDraftDescription = (clubSlug: ClubSlug, request: DraftDescriptionRequest) =>
      exclusiveRepository.requestDraftDescription(clubSlug, request);

    const openDraftDescriptionSidebar = async () => {
      const modal = createDraftDescriptionSidebar();
      sidebarBus.open<DraftDescriptionSidebarOptions>({
        component: modal,
        isClosable: true,
        title: t('completion.draftDescriptionSidebar.title'),
        options: {
          clubSlug: props.clubSlug,
          name: translationFor(exclusiveForm.value.nameTranslations, 'fr'),
          requestDraftDescription,
          onDraftReceived: updateDraft,
        },
      });
    };

    const updateCoinsAmount = (coinsAmount: number): void => {
      exclusiveForm.value.priceCoinsAmount = coinsAmount;
      priceSimulation.value = coinsAmount * club.coin.price;
    };

    const updateNonDeductibleAmount = (coinsNonDeductibleAmount: number): void => {
      exclusiveForm.value.nonDeductibleAmount = club.coin.price * coinsNonDeductibleAmount;
    };

    const replaceDescriptionWithDraft = (language: Language) => {
      translationFor(exclusiveForm.value.descriptionTranslations, language).value = draftDescription.value;
    };

    const updateImage = (medias: Media) => (exclusiveForm.value.imageUrl = medias.url);

    const updateMedias = (medias: Media[]) => (exclusiveForm.value.medias = medias);

    const resetTextField = (field: ExclusiveTextFormInput) => {
      exclusiveForm.value[field] = props.formValue[field];
    };

    const resetNameTranslation = (language: Language) => {
      translationFor(exclusiveForm.value.nameTranslations, language).value = translationFor(
        props.formValue.nameTranslations,
        language
      ).value;
    };

    const resetDescriptionTranslation = (language: Language) => {
      translationFor(exclusiveForm.value.descriptionTranslations, language).value = translationFor(
        props.formValue.descriptionTranslations,
        language
      ).value;
    };

    const resetNumberField = (field: ExclusiveNumberFormInput) => {
      exclusiveForm.value[field] = props.formValue[field];
    };

    const resetAuctionLotNumber = () => {
      exclusiveForm.value.auctionLotNumber = props.formValue.auctionLotNumber;
    };

    const resetTaxDeduction = () => {
      exclusiveForm.value.isTaxDeductible = props.formValue.isTaxDeductible;
      exclusiveForm.value.nonDeductibleAmount = props.formValue.nonDeductibleAmount;
    };

    const resetNonDeductibleAmount = () => {
      exclusiveForm.value.nonDeductibleAmount = props.formValue.nonDeductibleAmount;
    };

    const resetCategoryField = () => {
      exclusiveForm.value.category = props.formValue.category;
    };

    const resetVisibleField = () => {
      exclusiveForm.value.visible = props.formValue.visible;
    };

    const resetImageUrl = () => {
      exclusiveForm.value.imageUrl = props.formValue.imageUrl;
    };

    const resetMedias = () => {
      exclusiveForm.value.medias = props.formValue.medias;
    };

    const hasFieldChanged = (field: keyof ExclusiveFormUi) => exclusiveForm.value[field] !== props.formValue[field];

    const hasMediasChanged = () => JSON.stringify(exclusiveForm.value.medias) !== JSON.stringify(props.formValue.medias);

    const hasNameTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.nameTranslations, translation.language).value;

    const hasDescriptionTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.descriptionTranslations, translation.language).value;

    const computeSlug = (name: string) => {
      if (props.isUpdating) {
        return;
      }

      exclusiveForm.value.slug =
        name &&
        name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .match(exclusiveNameForSlugMatcher)!
          .map(x => x.toLowerCase())
          .join('-');
    };

    const onNameFocusOut = (translation: TranslationUi) => {
      if (translation.language !== 'fr') {
        return;
      }

      computeSlug(translation.value);
    };

    const togglePreviewFor = (translation: TranslationUi) => {
      hiddenPreviews.value[translation.language] = !hiddenPreviews.value[translation.language];
    };

    const updateTranslation = (translation: TranslationUi, updatedDescription: string) => {
      translation.value = updatedDescription;
    };

    const translateText = async (targetLanguage: string, translations: TranslationUi[], hasTranslation: ComputedRef<boolean>) => {
      if (hasTranslation.value && !isTranslationLoading.value) {
        isTranslationLoading.value = true;
        const frenchTranslation = translationFor(translations, 'fr');
        const translation = await clubRepository.translateText({
          text: frenchTranslation.value,
          sourceLanguage: 'fr',
          targetLanguage,
          clubSlug: props.clubSlug,
        });
        const index = translations.findIndex(translation => translation.language === targetLanguage);
        translations.splice(index, 1, {
          ...translations[index],
          value: translation,
        });
        isTranslationLoading.value = false;
      }
    };

    const translateName = (targetLanguage: string) => translateText(targetLanguage, exclusiveForm.value.nameTranslations, hasName);

    const translateDescription = (targetLanguage: string) =>
      translateText(targetLanguage, exclusiveForm.value.descriptionTranslations, hasDescription);

    const onDragStart = (event: DragEvent) => dragStart(event, exclusiveForm.value);

    const onDragOver = async (event: DragEvent) => dragOver(event);

    const onDrop = async (event: DragEvent) => {
      drop<ExclusiveFormUi>(event).ifPresent(value => (exclusiveForm.value = value));
    };

    return {
      auctions,
      categories,
      club,
      isEditIconShown,
      isTranslationLoading,
      hasFieldChanged,
      hasNameTranslationChanged,
      hasDescriptionTranslationChanged,
      hasMediasChanged,
      updateCoinsAmount,
      updateNonDeductibleAmount,
      exclusiveForm,
      taxDeductionCoinsAmount,
      hasDescription,
      hasName,
      translateDescription,
      translateName,
      mainImage,
      updateImage,
      updateMedias,
      confirm,
      replaceDescriptionWithDraft,
      resetDescriptionTranslation,
      resetTextField,
      resetNameTranslation,
      resetNumberField,
      resetCategoryField,
      resetAuctionLotNumber,
      resetTaxDeduction,
      resetNonDeductibleAmount,
      resetImageUrl,
      resetMedias,
      resetVisibleField,
      useLotNumbers,
      onDragStart,
      onDragOver,
      onDrop,
      onNameFocusOut,
      routeLeaveGuard,
      openDraftDescriptionSidebar,
      togglePreviewFor,
      previewVisibleFor,
      priceSimulation,
      priceSimulationText,
      priceSimulationMin,
      priceSimulationMax,
      priceSimulationStep,
      priceSimulationNonDeductible,
      formatDescription,
      slugAlreadyExists,
      t,
      updateTranslation,
    };
  },
});
