import { defineComponent, PropType, ref } from 'vue';
import { Media } from '@/common/domain/Media';
import { MediasInputVue } from '@/staff/primary/media-input';
import { MediaType } from '@/common/domain/MediaType';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'MultipleMediasInput',

  components: { MediasInputVue },

  props: {
    medias: {
      type: Array as PropType<Media[]>,
      default: () => [],
    },
    touched: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['updated-medias'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const unsupportedFileCount = ref(0);

    const updateMedia = async (medias: Media[]) => {
      emit('updated-medias', { value: medias });
    };

    const onUnsupportedFile = (fileCount: number) => (unsupportedFileCount.value = fileCount);

    return {
      t,
      MediaType,
      updateMedia,
      unsupportedFileCount,
      onUnsupportedFile,
    };
  },
});
