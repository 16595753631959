import { GiveawayAnswerToCreate } from '@/staff/domain/club/giveaway/GiveawayAnswerToCreate';
import { GiveawayAnswerToUpdate } from '@/staff/domain/club/giveaway/GiveawayAnswerToUpdate';
import { Optional } from '@/common/domain/Optional';
import { emptyTranslationsUi, fromTranslations, fromTranslationUi, TranslationUi } from '@/common/primary/Translation.ui';
import { GiveawayAnswerText } from '@/staff/domain/club/giveaway/GiveawayAnswerText';
import { GiveawayAnswer } from '@/staff/domain/club/giveaway/GiveawayAnswer';

export interface GiveawayAnswerFormUi {
  id?: string;
  text: string;
  textTranslations: TranslationUi[];
}

export const fromGiveawayAnswer = (giveawayAnswer: GiveawayAnswer, languages: string[]): GiveawayAnswerFormUi => ({
  id: giveawayAnswer.id,
  text: giveawayAnswer.text,
  textTranslations: fromTranslations(languages, giveawayAnswer.textTranslations!),
});

export const toGiveawayAnswerToCreate = (giveawayAnswerUi: GiveawayAnswerFormUi): GiveawayAnswerToCreate => ({
  textTranslations: giveawayAnswerUi.textTranslations.map(fromTranslationUi<GiveawayAnswerText>),
});

export const toGiveawayAnswerToUpdate = (giveawayAnswerUi: GiveawayAnswerFormUi): GiveawayAnswerToUpdate => ({
  id: Optional.ofUndefinable(giveawayAnswerUi.id),
  textTranslations: giveawayAnswerUi.textTranslations.map(fromTranslationUi<GiveawayAnswerText>),
});

export const cloneAnswerFormUi = (giveawayAnswerUi: GiveawayAnswerFormUi): GiveawayAnswerFormUi => ({
  text: giveawayAnswerUi.text,
  textTranslations: giveawayAnswerUi.textTranslations,
});

export const emptyAnswerFormUi = (languages: string[]): GiveawayAnswerFormUi => ({
  text: '',
  textTranslations: emptyTranslationsUi(languages),
});
