import { Auction } from '@/staff/domain/exclusive/Auction';
import { AuctionStatus } from '@/staff/domain/exclusive/AuctionStatus';
import { BackendTranslation, fromBackendTranslation } from '@/common/secondary/BackendTranslation';
import { AuctionName } from '@/staff/domain/exclusive/AuctionName';
import { Optional } from '@/common/domain/Optional';

export interface BackendAuction {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  status: AuctionStatus;
  visible: boolean;
  anonymous: boolean;
  useLotNumbers: boolean;
  nameTranslations?: BackendTranslation[];
}

export const toAuction = (backendAuction: BackendAuction): Auction => ({
  id: backendAuction.id,
  name: backendAuction.name,
  startDate: new Date(backendAuction.startDate),
  endDate: new Date(backendAuction.endDate),
  status: backendAuction.status,
  visible: backendAuction.visible,
  anonymous: backendAuction.anonymous,
  useLotNumbers: backendAuction.useLotNumbers,
  nameTranslations: Optional.ofUndefinable(backendAuction.nameTranslations).map(names => names.map(fromBackendTranslation<AuctionName>)),
});
