import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayParticipation } from '@/staff/domain/club/giveaway/GiveawayParticipation';
import { BackendGiveawayParticipation, toGiveawayParticipation } from '@/staff/secondary/club/giveaway/BackendGiveawayParticipation';
import {
  BackendGiveawayQuestionStatistics,
  toGiveawayQuestionStatistics,
} from '@/staff/secondary/club/giveaway/BackendGiveawayQuestionStatistics';
import { GiveawayQuestionStatistics } from '@/staff/domain/club/giveaway/GiveawayQuestionStatistics';
import { GiveawayRepository } from '@/staff/domain/club/giveaway/GiveawayRepository';
import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import { toBackendGiveawayToCreate } from '@/staff/secondary/club/giveaway/BackendGiveawayToCreate';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { BackendGiveaway, sortGiveawaysByDateDesc, toGiveaway } from '@/staff/secondary/club/giveaway/BackendGiveaway';
import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { toBackendGiveawayToUpdate } from '@/staff/secondary/club/giveaway/BackendGiveawayToUpdate';
import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';
import { toBackendGiveawayClosureToCreate } from '@/staff/secondary/club/giveaway/BackendGiveawayClosureToCreate';

export class BackendGiveawayRepository implements GiveawayRepository {
  constructor(private backendCaller: BackendCaller) {}

  async create(clubSlug: ClubSlug, giveawayToCreate: GiveawayToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/giveaways`, toBackendGiveawayToCreate(giveawayToCreate));
  }

  list(clubSlug: ClubSlug): Promise<Giveaway[]> {
    return this.backendCaller
      .get<BackendGiveaway[]>(`api/clubs/${clubSlug}/giveaways-participations`)
      .then(giveaways => giveaways.data.map(toGiveaway).sort(sortGiveawaysByDateDesc));
  }

  get(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<Giveaway> {
    return this.backendCaller
      .get<BackendGiveaway>(`api/clubs/${clubSlug}/giveaways/${giveawayId}`, {
        params: { 'with-translations': true, 'with-right-answers': true },
      })
      .then(giveaway => toGiveaway(giveaway.data));
  }

  update(giveawayToUpdate: GiveawayToUpdate): Promise<Giveaway> {
    return this.backendCaller
      .patch<BackendGiveaway>(
        `api/clubs/${giveawayToUpdate.clubSlug}/giveaways/${giveawayToUpdate.id}`,
        toBackendGiveawayToUpdate(giveawayToUpdate)
      )
      .then(({ data }) => toGiveaway(data));
  }

  async close(closureToCreate: GiveawayClosureToCreate): Promise<void> {
    await this.backendCaller.put<BackendGiveaway>(
      `api/clubs/${closureToCreate.clubSlug}/giveaways/${closureToCreate.giveawayId}/closure`,
      toBackendGiveawayClosureToCreate(closureToCreate)
    );
  }

  listGiveawayParticipations(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayParticipation[]> {
    return this.backendCaller
      .get<BackendGiveawayParticipation[]>(`api/clubs/${clubSlug}/giveaways/${giveawayId}/participations`)
      .then(giveawayParticipations => giveawayParticipations.data.map(toGiveawayParticipation));
  }

  getQuestionsStatistics(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayQuestionStatistics[]> {
    return this.backendCaller
      .get<BackendGiveawayQuestionStatistics[]>(`api/clubs/${clubSlug}/giveaways/${giveawayId}/questions-statistics`)
      .then(questionStatistics => questionStatistics.data.map(toGiveawayQuestionStatistics));
  }
}
