include ../loading-table.mixin.pug

template(v-if='coinsHoldings.isLoading()')
  +loading-table
template(v-else)
  .fp-vertical-spacing(v-if='!hasCoinsHoldings' data-test='no-coins-holdings')
    .fp-vertical-spacing--line.-align-center
      i.fp-icon.fp-glyph-close-circle.-s50.-color-shade-300
    .fp-vertical-spacing--line.-align-center
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold.-text-lg {{ t('coinsHoldings.empty.title') }}
        .fp-vertical-spacing--line.-align-center {{ t('coinsHoldings.empty.noCoinsHoldings') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='coins-holdings-table')
        thead
          tr
            th {{ t('coinsHoldings.table.fanEmail') }}
            th {{ t('coinsHoldings.table.totalCost') }}
            th {{ t('coinsHoldings.table.tokensAmount') }}
        tbody
          tr(v-for='(coinsHolding, index) in coinsHoldings.value().coinsHoldings' :key='index' :data-test='`coins-holding-${index}`')
            td {{ coinsHolding.fanEmail }}
            td {{ coinsHolding.amount.totalCost.text }}
            td {{ coinsHolding.amount.coins.text }}
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        span.fp-text {{ t('coinsHoldings.totalAmount') }}
        ExclusivePriceVue(:pricing='coinsHoldings.value().totalTokens' data-test='total-coins-holdings')
