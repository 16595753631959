import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';
import { ProgramFormUi, emptyProgramForm, toProgramToCreate } from '@/staff/primary/program/program-form/ProgramForm.ui';
import { ProgramFormVue } from '@/staff/primary/program/program-form';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { Loader } from '@/common/primary/loader/Loader';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'CreateProgram',

  components: {
    FairplayerButtonVue,
    ProgramFormVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const logger = inject(loggerKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const alertBus = inject(alertBusKey)!;

    const club = clubRepository.getCurrentClub();
    const programForm = ref(Loader.loading<ProgramFormUi>());
    const creating = ref(false);

    onMounted(async () => programForm.value.loaded(emptyProgramForm(club.languages)));

    const createProgram = async (programForm: ProgramFormUi): Promise<void> => {
      creating.value = true;

      return programRepository
        .create(toProgramToCreate(programForm, props.clubSlug))
        .then(program => goToCreatedProgram(program.slug))
        .catch(error => logger.error('Failed to create program', error))
        .finally(() => (creating.value = false));
    };

    const goToCreatedProgram = (slug: string) => {
      alertBus.alert('toasts.success.program.creation', 'success');

      router.push({ name: 'updateProgram', params: { clubSlug: props.clubSlug, programSlug: slug } });
    };

    const onGoBack = () => {
      router.push({ name: 'programs', params: { clubSlug: props.clubSlug } });
    };

    return {
      createProgram,
      programForm,
      creating,
      onGoBack,
      t,
    };
  },
});
