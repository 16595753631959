import { RouterTab } from '@/router/RouterTab';

export interface MenuItemUi {
  routeName: string;
  translateKey: string;
  icon: string;
  routerTab: RouterTab;
}

export const overviewItem: MenuItemUi = {
  routeName: 'clubOverview',
  translateKey: 'overview',
  icon: 'piechart',
  routerTab: RouterTab.OVERVIEW,
};

export const giveawaysItem: MenuItemUi = {
  routeName: 'giveaways',
  translateKey: 'giveaways',
  icon: 'gift',
  routerTab: RouterTab.GIVEAWAYS,
};

export const dueDiligencesItem: MenuItemUi = {
  routeName: 'giveaways',
  translateKey: 'dueDiligences',
  icon: 'identification-card',
  routerTab: RouterTab.GIVEAWAYS,
};

export const exclusivesItem: MenuItemUi = {
  routeName: 'exclusives',
  translateKey: 'exclusives',
  icon: 'shopping-bag',
  routerTab: RouterTab.EXCLUSIVES,
};

export const donationExclusivesItem: MenuItemUi = {
  routeName: 'exclusives',
  translateKey: 'donationExclusives',
  icon: 'hand-heart',
  routerTab: RouterTab.EXCLUSIVES,
};

export const auctionsItem: MenuItemUi = {
  routeName: 'auctions',
  translateKey: 'auctions',
  icon: 'gavel',
  routerTab: RouterTab.AUCTIONS,
};

export const coinsItem: MenuItemUi = {
  routeName: 'coins',
  translateKey: 'coins',
  icon: 'coin',
  routerTab: RouterTab.COINS,
};

export const programsItem: MenuItemUi = {
  routeName: 'programs',
  translateKey: 'programs',
  icon: 'newspaper',
  routerTab: RouterTab.PROGRAMS,
};

export const postsItem: MenuItemUi = {
  routeName: 'posts',
  translateKey: 'posts',
  icon: 'calendar-check',
  routerTab: RouterTab.POSTS,
};

export const fansItem: MenuItemUi = {
  routeName: 'fans',
  translateKey: 'members',
  icon: 'users',
  routerTab: RouterTab.FANS,
};
