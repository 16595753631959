import { GiveawayAnswer } from '@/staff/domain/club/giveaway/GiveawayAnswer';
import { BackendTranslation, fromBackendTranslation } from '@/common/secondary/BackendTranslation';
import { GiveawayAnswerText } from '@/staff/domain/club/giveaway/GiveawayAnswerText';

export interface BackendGiveawayAnswer {
  id: string;
  text: string;
  textTranslations?: BackendTranslation[];
}

export const toGiveawayAnswer = (backendGiveawayAnswer: BackendGiveawayAnswer): GiveawayAnswer => ({
  id: backendGiveawayAnswer.id,
  text: backendGiveawayAnswer.text,
  textTranslations: backendGiveawayAnswer.textTranslations?.map(fromBackendTranslation<GiveawayAnswerText>),
});
