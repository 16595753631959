import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { emptyPaymentPartnerForm, PaymentPartnerFormUi } from '@/staff/primary/club/payment-partner-form/PaymentPartnerForm.ui';

export default defineComponent({
  name: 'PaymentPartnerForm',

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const paymentPartnerForm = ref<PaymentPartnerFormUi>(emptyPaymentPartnerForm());

    const confirm = () => {
      emit('confirm', paymentPartnerForm.value);
    };

    return {
      t,
      confirm,
      paymentPartnerForm,
    };
  },
});
