import { computed, defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { giveawayRepositoryKey } from '@/staff/domain/club/giveaway/GiveawayRepository';
import { fromGiveaway, GiveawayUi } from '@/staff/primary/giveaway/giveaways-table/Giveaway.ui';
import { Loader } from '@/common/primary/loader/Loader';
import { loggerKey } from '@/common/domain/Logger';
import { DropdownVue } from '@/common/primary/dropdown';
import { DropdownItemVue } from '@/common/primary/dropdown/dropdown-item';
import { DropdownSeparatorVue } from '@/common/primary/dropdown/dropdown-separator';

export default defineComponent({
  name: 'GiveawaysTable',

  components: { DropdownVue, DropdownItemVue, DropdownSeparatorVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    isGiveaway: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const giveawayRepository = inject(giveawayRepositoryKey)!;

    const giveaways = ref(Loader.loading<GiveawayUi[]>());
    const hasGiveaways = computed(() => !giveaways.value.isLoading() && giveaways.value.value().length > 0);

    onMounted(() => listGiveaways());

    const listGiveaways = () => {
      giveawayRepository
        .list(props.clubSlug)
        .then(retrievedGiveaways => giveaways.value.loaded(retrievedGiveaways.map(fromGiveaway)))
        .catch(error => {
          giveaways.value.loaded([]);
          logger.error('Failed to retrieve giveaways', error);
        });
    };

    return {
      giveaways,
      hasGiveaways,
      t,
    };
  },
});
