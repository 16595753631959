include ../../../../common/primary/select/select.mixin.pug

form.fp-card.-highlighted(autocomplete='on' @submit.prevent='confirm' @dragover.prevent='onDragOver' @drop.prevent='onDrop' data-test='post.form')
  .fp-vertical-spacing
    .fp-vertical-spacing--line(v-for='nameTranslation in postForm.nameTranslations' :key='nameTranslation.language')
      label.fp-field
        .fp-horizontal-spacing.-items-center.-s-sm(:draggable='true' @dragstart='onDragStart' data-test='draggable')
          .fp-horizontal-spacing--column
            | {{ t('postForm.name') }}
          .fp-horizontal-spacing--column
            img.fp-illustration.-xs(:class='`-flag-${nameTranslation.language}`' :data-test='`name-flag-${nameTranslation.language}`')
        .fp-horizontal-spacing--column
          .fp-field--actions
            template(v-if='nameTranslation.language !== "fr"')
              i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
              i.fp-icon.fp-glyph-translate(v-else @click='translateName(nameTranslation.language)' :class='hasName ? "-pointer" : "-color-shade-400"' :title='t(\'exclusiveForm.translate\')' :data-test='`translate-name-${nameTranslation.language}`')
            i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetNameTranslation(nameTranslation.language)' :class='hasNameTranslationChanged(nameTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-name-${nameTranslation.language}`' :title='t(\'undo\')')
          input.fp-input-text(v-model='nameTranslation.value' @focusout='onNameFocusOut(nameTranslation)' :class='{"-touched": isUpdating && hasNameTranslationChanged(nameTranslation)}' required :data-test='`name-${nameTranslation.language}`')
    .fp-vertical-spacing--line
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line
          label.fp-field(data-test='slug-label')
            | {{ t(`postForm.slugOn${isUpdating ? 'Update' : 'Create'}`) }}
            .fp-field--actions
              i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("slug")' :class='hasFieldChanged("slug") ? "-pointer" : "-color-shade-400"' data-test='reset-slug' :title='t(\'undo\')')
            input.fp-input-text(v-model='postForm.slug' pattern='[a-z0-9]+(?:-[a-z0-9]+)*' :class='{"-touched": isUpdating && hasFieldChanged("slug")}' required data-test='slug')
        .fp-vertical-spacing--line(v-if='slugAlreadyExists' data-test='slug-already-exists')
          span.fp-text.-color-danger {{ t('postForm.slugAlreadyExists') }}
    .fp-vertical-spacing--line
      label.fp-field(data-test='program-slug')
        | {{ t('postForm.program.label') }}
        .fp-field--actions
          i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetProgram' :class='hasFieldChanged("programSlug") ? "-pointer" : "-color-shade-400"' data-test='reset-program' :title='t(\'undo\')')
        +fp-select
          select(v-model='postForm.programSlug' name='program' :class='{"-touched": isUpdating && hasFieldChanged("programSlug")}' data-test='program')
            option(v-for='(program, index) in programsList' :key='index' :value='program.slug' v-text='program.name' :data-test='`program-${index}`')
    .fp-vertical-spacing--line
      .fp-responsive-grid.-cols2
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('postForm.date') }}
            .fp-field--actions
              i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetDateField("date")' :class='hasDateFieldChanged("date") ? "-pointer" : "-color-shade-400"' data-test='reset-date' :title='t(\'undo\')')
            input.fp-input-text(v-model='postForm.date.date' type='date' required data-test='date.date')
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('postForm.time') }}
            .fp-field--actions
              i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetDateField("time")' :class='hasDateFieldChanged("time") ? "-pointer" : "-color-shade-400"' data-test='reset-time' :title='t(\'undo\')')
            input.fp-input-text(v-model='postForm.date.time' type='time' required data-test='date.time')
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-equivalent
        .fp-horizontal-spacing--column(data-test='main-image')
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              .fp-horizontal-spacing
                .fp-horizontal-spacing--column.-expand
                  label.fp-field(data-test='image-input') {{ t('postForm.mainImage')  }}
                .fp-horizontal-spacing--column
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetImageUrl' :class='hasFieldChanged("imageUrl") ? "-pointer" : "-color-shade-400"' data-test='reset-image' :title='t(\'undo\')')
            .fp-vertical-spacing--line
              SingleImageInputVue(:media='mainImage' :touched='isUpdating && hasFieldChanged("imageUrl")' :required='!isUpdating' @updated-media='updatedMedia => updateImage(updatedMedia.value)')
        .fp-horizontal-spacing--column(data-test='extra-medias')
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              .fp-horizontal-spacing
                .fp-horizontal-spacing--column.-expand
                  label.fp-field(data-test='extra-medias') {{ t('postForm.extraMedia')  }}
                .fp-horizontal-spacing--column
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetMedias' :class='hasMediasChanged() ? "-pointer" : "-color-shade-400"' data-test='reset-medias' :title='t(\'undo\')')
            .fp-vertical-spacing--line
              MultipleMediasInputVue(:medias='postForm.medias' :touched='isUpdating && hasMediasChanged()' @updated-medias='updatedMedia => updateMedias(updatedMedia.value)')
    .fp-vertical-spacing--line(v-for='descriptionTranslation in postForm.descriptionTranslations' :key='descriptionTranslation.language')
      .fp-horizontal-spacing.-equivalent
        .fp-horizontal-spacing--column
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              label.fp-field
                .fp-horizontal-spacing.-items-center.-s-sm
                  .fp-horizontal-spacing--column
                    | {{ t('postForm.description') }}
                  .fp-horizontal-spacing--column
                    img.fp-illustration.-xs(:class='`-flag-${descriptionTranslation.language}`' :data-test='`description-flag-${descriptionTranslation.language}`')
                  .fp-field--actions
                    template(v-if='descriptionTranslation.language === "fr"')
                      i.fp-icon.fp-glyph-magic-wand.-pointer(@click='openDraftDescriptionSidebar()' :data-test='`request-draft-description-${descriptionTranslation.language}`' :title='t(\'completion.generate\')')
                    template(v-if='descriptionTranslation.language === "en"')
                      i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                      i.fp-icon.fp-glyph-translate(v-else @click='translateDescription(descriptionTranslation.language)' :class='hasDescription ? "-pointer" : "-color-shade-400"' :title='t(\'postForm.translate\')' :data-test='`translate-description-${descriptionTranslation.language}`')
                    i.fp-icon.-pointer(@click='togglePreviewFor(descriptionTranslation)' :class='previewVisibleFor(descriptionTranslation) ? "fp-glyph-eye-slash" : "fp-glyph-eye"' :data-test='`toggle-preview-${descriptionTranslation.language}`' :title='previewVisibleFor(descriptionTranslation) ? t("hidePreview") : t("showPreview")')
                    i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetDescriptionTranslation(descriptionTranslation.language)' :class='hasDescriptionTranslationChanged(descriptionTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-description-${descriptionTranslation.language}`' :title='t(\'undo\')')
            .fp-vertical-spacing--line
              MarkdownEditorVue(:text='descriptionTranslation' @updated-value='text => updateTranslation(descriptionTranslation, text.value)' :touched='isUpdating && hasDescriptionTranslationChanged(descriptionTranslation)' :data-test='`description-${descriptionTranslation.language}`' :disabled='isTranslationLoading')
        .fp-horizontal-spacing--column(v-if='previewVisibleFor(descriptionTranslation)')
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              .fp-text.-text-semi-bold {{ t('postForm.preview') }}
            .fp-vertical-spacing--line
              .fp-text.-text-sm(v-html='formatDescription(descriptionTranslation.value)' :data-test='`preview-description-${descriptionTranslation.language}`')
