import { ClubState } from '@/staff/domain/club/ClubState';
import { Club } from '@/staff/domain/club/Club';
import { Coin } from '@/common/domain/token/Coin';
import { ClubModel } from '@/staff/domain/club/ClubModel';
import { ClubPaymentPartnerAccountStatusUi } from '@/staff/primary/club/ClubPaymentPartnerAccountStatus.ui';
import { ClubPaymentPartnerAccountStatus } from '@/staff/domain/club/ClubPaymentPartnerAccountStatus';

export interface ClubUi {
  name: string;
  platformName: string;
  slug: string;
  isFoundation: boolean;
  isMarketplace: boolean;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
  coin: Coin;
  state: ClubState;
  referralNeeded: boolean;
  coinsEnabled: boolean;
  fiatAccepted: boolean;
  paymentPartnerAccountStatus: ClubPaymentPartnerAccountStatusUi;
}

const fromPaymentPartnerAccountStatus =
  () =>
  (status: ClubPaymentPartnerAccountStatus): ClubPaymentPartnerAccountStatusUi =>
    ClubPaymentPartnerAccountStatusUi[status];

export const fromClub = (club: Club): ClubUi => ({
  name: club.name,
  platformName: club.platformName,
  slug: club.slug,
  isFoundation: club.model === ClubModel.FOUNDATION,
  isMarketplace: club.model === ClubModel.MARKETPLACE,
  logoUrl: club.logoUrl,
  bannerUrl: club.bannerUrl,
  webappUrl: club.webappUrl,
  coin: club.coin,
  state: club.state,
  referralNeeded: club.referralNeeded,
  coinsEnabled: club.coinsEnabled,
  fiatAccepted: club.fiatAccepted,
  paymentPartnerAccountStatus: club.paymentPartnerAccountStatus
    .map(fromPaymentPartnerAccountStatus())
    .orElse(ClubPaymentPartnerAccountStatusUi.NOT_ONBOARDED),
});
