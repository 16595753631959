import { alertBusKey } from '@/common/domain/alert/AlertBus';
import router from '@/router/router';
import { ClubFormUi, fromClub, toClubToUpdate } from '@/staff/primary/club/club-form/ClubForm.ui';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ClubFormVue } from '@/staff/primary/club/club-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';
import { isClubToUpdateEmpty } from '@/staff/domain/club/ClubToUpdate';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { Loader } from '@/common/primary/loader/Loader';

export default defineComponent({
  name: 'UpdateClub',

  components: {
    ClubFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const club = clubRepository.getCurrentClub();
    const alertBus = inject(alertBusKey)!;

    const clubForm = ref(Loader.loading<ClubFormUi>());
    const updating = ref(false);

    const onConfirmUpdate = (updatedClubForm: ClubFormUi): Promise<void> => {
      const clubToUpdate = toClubToUpdate(updatedClubForm, club, clubForm.value.value().recipientEmails);
      if (isClubToUpdateEmpty(clubToUpdate)) {
        return Promise.resolve();
      }

      updating.value = true;

      return clubRepository
        .update(clubToUpdate)
        .then(async updatedClub => {
          const newRecipients = await clubRepository.getRecipientEmails(props.clubSlug);
          clubForm.value.loaded(fromClub(updatedClub, newRecipients, updatedClub.languages));
          alertBus.alert('toasts.success.club.update', 'success');
        })
        .catch(error => logger.error('Failed to update club', error))
        .finally(() => (updating.value = false));
    };

    const onGoBack = () => {
      router.push({ name: 'clubOverview', params: { clubSlug: props.clubSlug } });
    };

    onMounted(async () => {
      clubRepository
        .getRecipientEmails(props.clubSlug)
        .then(emails => clubForm.value.loaded(fromClub(club, emails, club.languages)))
        .catch(error => {
          logger.error('Failed to retrieve recipient emails', error);
        });
    });

    return {
      clubForm,
      club,
      updating,
      onGoBack,
      onConfirmUpdate,
      t,
    };
  },
});
