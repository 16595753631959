import { GiveawayQuestionToCreate } from '@/staff/domain/club/giveaway/GiveawayQuestionToCreate';
import { GiveawayQuestionToUpdate } from '@/staff/domain/club/giveaway/GiveawayQuestionToUpdate';
import { GiveawayQuestion } from '@/staff/domain/club/giveaway/GiveawayQuestion';
import { Optional } from '@/common/domain/Optional';
import { GiveawayQuestionTypeUi } from '@/staff/primary/giveaway/create-giveaway/GiveawayQuestionType.ui';
import { GiveawayAnswerToCreate } from '@/staff/domain/club/giveaway/GiveawayAnswerToCreate';
import { GiveawayAnswerToUpdate } from '@/staff/domain/club/giveaway/GiveawayAnswerToUpdate';
import { v4 as uuidv4 } from 'uuid';
import { emptyMarkdownTextUi, MarkdownTextUi } from '@/common/primary/markdown/MarkdownText.ui';
import { toRaw } from 'vue';
import { emptyTranslationsUi, fromTranslations, fromTranslationUi, TranslationUi } from '@/common/primary/Translation.ui';
import { GiveawayQuestionTitle } from '@/staff/domain/club/giveaway/GiveawayQuestionTitle';
import { Language } from '@/common/domain/Language';
import { Translation } from '@/common/domain/Translation';
import {
  cloneAnswerFormUi,
  emptyAnswerFormUi,
  fromGiveawayAnswer,
  GiveawayAnswerFormUi,
  toGiveawayAnswerToCreate,
  toGiveawayAnswerToUpdate,
} from '@/staff/primary/giveaway/GiveawayAnswerForm.ui';

const FREE_TEXT_TYPES = [GiveawayQuestionTypeUi.PARAGRAPH, GiveawayQuestionTypeUi.TEXT];

export interface GiveawayQuestionFormUi {
  id?: string;
  randomKey: string;
  title: string;
  preTitle: MarkdownTextUi;
  answers: GiveawayAnswerFormUi[];
  rightAnswer?: GiveawayAnswerFormUi;
  type: GiveawayQuestionTypeUi;
  required: boolean;
  quizMode: boolean;
  titleTranslations: TranslationUi[];
  preTitleTranslations: TranslationUi[];
}

const toGiveawayQuestionTypeUi = (giveawayQuestion: GiveawayQuestion): GiveawayQuestionTypeUi => {
  if (giveawayQuestion.answers.length !== 0) {
    return GiveawayQuestionTypeUi.CHOICE;
  }

  if (giveawayQuestion.multiline) {
    return GiveawayQuestionTypeUi.PARAGRAPH;
  }

  return GiveawayQuestionTypeUi.TEXT;
};

const toRightAnswerUi = (
  giveawayQuestion: GiveawayQuestion,
  answers: GiveawayAnswerFormUi[],
  languages: string[]
): GiveawayAnswerFormUi | undefined => {
  if (giveawayQuestion.rightAnswer.isEmpty()) {
    return undefined;
  }
  if (giveawayQuestion.answers.length === 0) {
    return emptyAnswerFormUi(languages);
  }

  return giveawayQuestion.rightAnswer.map(answer => answers.find(a => a.id === answer.id)!).orElse(emptyAnswerFormUi(languages));
};

export const fromGiveawayQuestion = (giveawayQuestion: GiveawayQuestion, languages: Language[]): GiveawayQuestionFormUi => {
  const answers = giveawayQuestion.answers.map(answers => fromGiveawayAnswer(answers, languages));

  return {
    id: giveawayQuestion.id,
    randomKey: uuidv4(),
    title: giveawayQuestion.title,
    answers: answers,
    rightAnswer: toRightAnswerUi(giveawayQuestion, answers, languages),
    type: toGiveawayQuestionTypeUi(giveawayQuestion),
    required: giveawayQuestion.required,
    quizMode: giveawayQuestion.quizMode,
    preTitle: giveawayQuestion.preTitle
      .map(preTitle => ({
        value: preTitle,
      }))
      .orElse(emptyMarkdownTextUi()),
    titleTranslations: fromTranslations(languages, giveawayQuestion.titleTranslations!),
    preTitleTranslations: fromTranslations(languages, giveawayQuestion.preTitleTranslations!.orElse([])),
  };
};

const toGiveawayQuestionAnswersToCreate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToCreate[] => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return [];
  }

  return giveawayQuestionFormUi.answers.map(toGiveawayAnswerToCreate);
};

const toGiveawayQuestionRightAnswerToCreate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): Optional<GiveawayAnswerToCreate> => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return Optional.empty();
  }
  if (!giveawayQuestionFormUi.quizMode) {
    return Optional.empty();
  }
  if (!giveawayQuestionFormUi.rightAnswer) {
    return Optional.empty();
  }
  return Optional.of(toGiveawayAnswerToCreate(giveawayQuestionFormUi.rightAnswer));
};

export const toGiveawayQuestionToCreate = (form: GiveawayQuestionFormUi): GiveawayQuestionToCreate => ({
  answers: toGiveawayQuestionAnswersToCreate(form),
  rightAnswer: toGiveawayQuestionRightAnswerToCreate(form),
  required: form.required,
  quizMode: form.quizMode,
  multiline: form.type === GiveawayQuestionTypeUi.PARAGRAPH,
  titleTranslations: form.titleTranslations.map(fromTranslationUi<GiveawayQuestionTitle>),
  preTitleTranslations: toOptionalPreTitleTranslations(form.preTitleTranslations),
});

const toGiveawayAnswersToUpdate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToUpdate[] => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return [];
  }

  return giveawayQuestionFormUi.answers.map(toGiveawayAnswerToUpdate);
};

const toGiveawayRightAnswerToUpdate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToUpdate | undefined => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return undefined;
  }
  if (!giveawayQuestionFormUi.quizMode) {
    return undefined;
  }
  if (!giveawayQuestionFormUi.rightAnswer) {
    return undefined;
  }

  return toGiveawayAnswerToUpdate(giveawayQuestionFormUi.rightAnswer);
};

const freeTextQuestion = (giveawayQuestionFormUi: GiveawayQuestionFormUi) => FREE_TEXT_TYPES.includes(giveawayQuestionFormUi.type);

const toOptionalPreTitleTranslations = (preTitleTranslations: TranslationUi[]): Optional<Translation<string>[]> => {
  const translations = preTitleTranslations.filter(t => t.value).map(fromTranslationUi);
  if (translations.length > 0) {
    return Optional.of(translations);
  }

  return Optional.empty();
};

export const toGiveawayQuestionToUpdate = (form: GiveawayQuestionFormUi): GiveawayQuestionToUpdate => ({
  id: Optional.ofUndefinable(form.id),
  answers: toGiveawayAnswersToUpdate(form),
  rightAnswer: Optional.ofUndefinable(toGiveawayRightAnswerToUpdate(form)),
  required: form.required,
  quizMode: form.quizMode,
  multiline: form.type === GiveawayQuestionTypeUi.PARAGRAPH,
  titleTranslations: form.titleTranslations.filter(t => t.value).map(fromTranslationUi),
  preTitleTranslations: toOptionalPreTitleTranslations(form.preTitleTranslations),
});

export const emptyQuestionFormUi = (languages: string[]): GiveawayQuestionFormUi => {
  const answers = [emptyAnswerFormUi(languages), emptyAnswerFormUi(languages)];
  return {
    randomKey: uuidv4(),
    title: '',
    answers,
    rightAnswer: answers[0],
    type: GiveawayQuestionTypeUi.CHOICE,
    required: true,
    quizMode: true,
    preTitle: emptyMarkdownTextUi(),
    titleTranslations: emptyTranslationsUi(languages),
    preTitleTranslations: emptyTranslationsUi(languages),
  };
};

export const cloneQuestionFormUi = (giveawayQuestionForm: GiveawayQuestionFormUi): GiveawayQuestionFormUi => {
  const newForm = JSON.parse(JSON.stringify(toRaw(giveawayQuestionForm)));

  return {
    ...newForm,
    id: undefined,
    randomKey: uuidv4(),
    answers: giveawayQuestionForm.answers.map(cloneAnswerFormUi),
    rightAnswer: Optional.ofUndefinable(giveawayQuestionForm.rightAnswer).map(cloneAnswerFormUi).orElseUndefined(),
  };
};
