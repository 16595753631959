import { PostToCreate } from '@/staff/domain/post/PostToCreate';
import { Url } from '@/common/domain/Url';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { PostDescription } from '@/staff/domain/post/PostDescription';
import { PostName } from '@/staff/domain/post/PostName';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';

export interface BackendPostToCreate {
  slug: string;
  imageUrl: Url;
  date: string;
  programSlug?: string;
  medias: BackendMedia[];
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

export const toBackendPostToCreate = (postToCreate: PostToCreate): BackendPostToCreate => ({
  slug: postToCreate.slug,
  imageUrl: postToCreate.imageUrl,
  date: postToCreate.date.toISOString(),
  programSlug: postToCreate.programSlug.orElseUndefined(),
  medias: postToCreate.medias.map(toBackendMedia),
  nameTranslations: postToCreate.nameTranslations.map(toBackendTranslation<PostName>),
  descriptionTranslations: postToCreate.descriptionTranslations.map(toBackendTranslation<PostDescription>),
});
