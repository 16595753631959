import { ExclusiveToUpdate } from '@/staff/domain/exclusive/ExclusiveToUpdate';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { BackendAuctionLotToUpdate, fromAuctionLotToUpdate } from '@/staff/secondary/exclusive/BackendAuctionLotToUpdate';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';
import { toBackendArray } from '../toBackendArray';
import { BackendTaxDeductionToCreate, fromTaxDeductionToCreate } from '@/staff/secondary/exclusive/BackendTaxDeductionToCreate';
import { ExclusiveToUpdateDeletions } from '@/staff/domain/exclusive/ExclusiveToUpdateDeletions';

export interface BackendExclusiveToUpdate {
  slug?: string;
  imageUrl?: string;
  medias?: BackendMedia[];
  priceCoinsAmount?: number;
  visible?: boolean;
  stock?: number;
  category?: string;
  auctionLot?: BackendAuctionLotToUpdate;
  taxDeduction?: BackendTaxDeductionToCreate;
  nameTranslations?: BackendTranslation[];
  descriptionTranslations?: BackendTranslation[];
  deletions?: ExclusiveToUpdateDeletions;
}

export const toBackendExclusiveToUpdate = (exclusiveToUpdate: ExclusiveToUpdate): BackendExclusiveToUpdate => ({
  slug: exclusiveToUpdate.slug.orElseUndefined(),
  imageUrl: exclusiveToUpdate.imageUrl.orElseUndefined(),
  medias: toBackendArray(exclusiveToUpdate.medias, toBackendMedia),
  priceCoinsAmount: exclusiveToUpdate.priceCoinsAmount.orElseUndefined(),
  visible: exclusiveToUpdate.visible.orElseUndefined(),
  stock: exclusiveToUpdate.stock.orElseUndefined(),
  category: exclusiveToUpdate.category.orElseUndefined(),
  auctionLot: exclusiveToUpdate.auctionLot.map(fromAuctionLotToUpdate).orElseUndefined(),
  taxDeduction: exclusiveToUpdate.taxDeduction.map(fromTaxDeductionToCreate).orElseUndefined(),
  nameTranslations: toBackendArray(exclusiveToUpdate.nameTranslations, toBackendTranslation<PostName>),
  descriptionTranslations: toBackendArray(exclusiveToUpdate.descriptionTranslations, toBackendTranslation<PostDescription>),
  deletions: exclusiveToUpdate.deletions.orElseUndefined(),
});
