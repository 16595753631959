PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='update-exclusive.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('exclusiveForm.updateTitle') }}
    .fp-vertical-spacing--line(v-if='!exclusiveForm.isLoading()')
      ExclusiveFormVue#update-exclusive-form(:club-slug='clubSlug' :is-updating='true' :form-value='exclusiveForm.value()' :tokens-price='exclusiveTokensPrice' :limited-update='hasBids' @confirm='onConfirmUpdate')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='update-exclusive.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'exclusiveForm.updateExclusive\')' :loading='updating' type='submit' form='update-exclusive-form' icon='edit' data-test='update-exclusive.submit')
