import { AlertBus } from '@/common/domain/alert/AlertBus';
import { TextClipboard } from '@/common/domain/TextClipboard';

export class FairplayerTextClipboard implements TextClipboard {
  constructor(
    private window: Window,
    private alertBus: AlertBus
  ) {}

  async copy(text: string) {
    await this.window.navigator.clipboard.writeText(text);
    this.alertBus.alert('toasts.success.clipboard.copied', 'success');
  }
}
