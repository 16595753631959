import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ExclusiveSlug } from '@/staff/domain/exclusive/ExclusiveSlug';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { Url } from '@/common/domain/Url';
import { Media } from '@/common/domain/Media';
import { Translation } from '@/common/domain/Translation';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { AuctionLotToUpdate } from '@/staff/domain/exclusive/AuctionLotToUpdate';
import { TaxDeduction } from '@/staff/domain/exclusive/TaxDeduction';
import { ExclusiveToUpdateDeletions } from '@/staff/domain/exclusive/ExclusiveToUpdateDeletions';

export interface ExclusiveToUpdate {
  clubSlug: ClubSlug;
  currentSlug: ExclusiveSlug;
  slug: Optional<ExclusiveSlug>;
  imageUrl: Optional<Url>;
  medias: Optional<Media[]>;
  priceCoinsAmount: Optional<number>;
  visible: Optional<boolean>;
  stock: Optional<number>;
  category: Optional<ExclusiveCategory>;
  auctionLot: Optional<AuctionLotToUpdate>;
  taxDeduction: Optional<TaxDeduction>;
  nameTranslations: Optional<Translation<ExclusiveName>[]>;
  descriptionTranslations: Optional<Translation<ExclusiveDescription>[]>;
  deletions: Optional<ExclusiveToUpdateDeletions>;
}

type UpdatableExclusiveField = keyof Omit<ExclusiveToUpdate, 'clubSlug' | 'currentSlug'>;

export const isExclusiveToUpdateEmpty = (exclusiveToUpdate: ExclusiveToUpdate) =>
  Object.keys(exclusiveToUpdate)
    .filter(key => key !== 'clubSlug' && key !== 'currentSlug')
    .map(key => key as UpdatableExclusiveField)
    .map(key => exclusiveToUpdate[key])
    .every(field => field.isEmpty());
