import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Translation } from '@/common/domain/Translation';
import { ClubPresentation } from '@/staff/domain/club/ClubPresentation';
import { ClubName } from '@/staff/domain/club/ClubName';
import { PlatformName } from '@/staff/domain/club/PlatformName';
import { Url } from '@/common/domain/Url';
import { Email } from '@/staff/domain/Email';
import { ClubTaxDeductionToUpdate } from '@/staff/domain/club/ClubTaxDeductionToUpdate';

export interface ClubToUpdate {
  slug: ClubSlug;
  name: Optional<ClubName>;
  platformName: Optional<PlatformName>;
  logoUrl: Optional<Url>;
  bannerUrl: Optional<Url>;
  contactEmail: Optional<Email>;
  taxDeduction: Optional<ClubTaxDeductionToUpdate>;
  recipientEmails: Optional<Email[]>;
  presentationTranslations: Optional<Translation<ClubPresentation>[]>;
}

type UpdatableExclusiveField = keyof Omit<ClubToUpdate, 'slug'>;

export const isClubToUpdateEmpty = (clubToUpdate: ClubToUpdate) =>
  Object.keys(clubToUpdate)
    .filter(key => key !== 'slug')
    .map(key => key as UpdatableExclusiveField)
    .map(key => clubToUpdate[key])
    .every(field => field.isEmpty());
