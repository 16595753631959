import { Club } from '@/staff/domain/club/Club';
import { ClubState } from '@/staff/domain/club/ClubState';
import { Coin } from '@/common/domain/token/Coin';
import { BackendPartner, toPartner } from '@/staff/secondary/club/BackendPartner';
import { Language } from '@/common/domain/Language';
import { ClubModel } from '@/staff/domain/club/ClubModel';
import { BackendTranslation, fromBackendTranslation } from '@/common/secondary/BackendTranslation';
import { ClubPresentation } from '@/staff/domain/club/ClubPresentation';
import { Optional } from '@/common/domain/Optional';
import { ClubPaymentPartnerAccountStatus } from '@/staff/domain/club/ClubPaymentPartnerAccountStatus';
import { BackendClubTaxDeduction, toClubTaxDeduction } from '@/staff/secondary/club/BackendClubTaxDeduction';

export interface BackendClub {
  id: number;
  name: string;
  platformName: string;
  slug: string;
  model: ClubModel;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
  ticker: string;
  currentPrice: number;
  state: ClubState;
  referralNeeded: boolean;
  coinsEnabled: boolean;
  fiatAccepted: boolean;
  contactEmail: string;
  taxDeduction: BackendClubTaxDeduction;
  paymentPartnerAccountStatus?: ClubPaymentPartnerAccountStatus;
  languages: Language[];
  partners: BackendPartner[];
  presentationTranslations?: BackendTranslation[];
}

const toCoin = (ticker: string, price: number): Coin => ({
  ticker,
  price,
});

export const toClub = (backendClub: BackendClub): Club => ({
  id: backendClub.id,
  name: backendClub.name,
  platformName: backendClub.platformName,
  slug: backendClub.slug,
  model: backendClub.model,
  logoUrl: backendClub.logoUrl,
  bannerUrl: backendClub.bannerUrl,
  webappUrl: backendClub.webappUrl,
  coin: toCoin(backendClub.ticker, backendClub.currentPrice),
  state: backendClub.state,
  referralNeeded: backendClub.referralNeeded,
  coinsEnabled: backendClub.coinsEnabled,
  fiatAccepted: backendClub.fiatAccepted,
  taxDeduction: toClubTaxDeduction(backendClub.taxDeduction),
  languages: backendClub.languages,
  contactEmail: backendClub.contactEmail,
  paymentPartnerAccountStatus: Optional.ofUndefinable(backendClub.paymentPartnerAccountStatus),
  partners: backendClub.partners.map(toPartner),
  presentationTranslations: backendClub.presentationTranslations?.map(fromBackendTranslation<ClubPresentation>),
});
