import { Loader } from '@/common/primary/loader/Loader';
import { computed, defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { BidWithExclusiveUi } from '@/staff/primary/auction/auction-details/BidWithExclusive.ui';
import { loggerKey } from '@/common/domain/Logger';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { ExclusiveUi, fromExclusive } from '@/staff/primary/exclusive/Exclusive.ui';
import { AuctionUi } from '@/staff/primary/exclusive/Auction.ui';

export default defineComponent({
  name: 'AuctionStatistics',

  props: {
    auction: {
      type: Object as PropType<AuctionUi>,
      required: true,
    },
    bids: {
      type: Object as PropType<Loader<BidWithExclusiveUi[]>>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const clubExclusives = ref(Loader.loading<ExclusiveUi[]>());
    const auctionExclusives = computed(() =>
      clubExclusives.value.value().filter(e => e.auctionLot !== undefined && e.auctionLot.auction.id === props.auction.id)
    );
    const club = fromClub(clubRepository.getCurrentClub());

    const bidders = computed(() => new Set(props.bids.value().map(bid => bid.bidder.id)).size);
    const exclusivesWithBids = computed(() => new Set(props.bids.value().map(bid => bid.exclusive.id)).size);
    const total = computed(() =>
      auctionExclusives.value
        .filter(e => e.auctionLot!.bids?.length > 0)
        .reduce((total, exclusive) => exclusive.auctionLot!.currentPrice.totalCost.value + total, 0)
    );

    const totalStartingPrice = computed(() =>
      auctionExclusives.value.reduce((total, exclusive) => exclusive.price.totalCost.value + total, 0)
    );

    onMounted(() => listAuctionExclusives());

    const listAuctionExclusives = () => {
      exclusiveRepository
        .getByClub(club.slug, club.coin)
        .then(exclusivesRetrieved => clubExclusives.value.loaded(exclusivesRetrieved.exclusives.map(fromExclusive)))
        .catch(error => {
          logger.error('Failed to retrieve auction exclusives', error);
          clubExclusives.value.loaded([]);
        });
    };

    return {
      clubExclusives,
      auctionExclusives,
      bidders,
      exclusivesWithBids,
      total,
      totalStartingPrice,
      t,
    };
  },
});
