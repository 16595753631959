include ../../../club/loading-table.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    .fp-horizontal-spacing.-items-center
      .fp-horizontal-spacing--column.-expand
        h2.fp-title.-color-shade-700 {{ t('auctionsPage.exclusives.title') }}
      .fp-horizontal-spacing--column
        router-link.fp-link.-no-text-decoration(:to='{ name: \'createExclusive\', query: { auctionId }}' data-test='create-auction-exclusive')
          FairplayerButtonVue(icon='plus' :text='t(\'auctionsPage.exclusives.create\')')
  .fp-vertical-spacing--line(v-if='!hasExclusives' data-test='no-exclusives')
    p {{ t('auctionsPage.noExclusives') }}
  .fp-vertical-spacing--line(v-else)
    .fp-vertical-spacing--line.-align-center
      i.fp-icon.fp-glyph-close-circle.-s50.-color-shade-300
    .fp-vertical-spacing--line.-align-center
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold.-text-lg {{ t('exclusivesPage.empty.title') }}
        .fp-vertical-spacing--line.-align-center {{ t('exclusivesPage.empty.noExclusives') }}
    .fp-vertical-spacing--line.-justify-center
      router-link.fp-link.fp-icon-with-text(:to='{ name: \'createExclusive\'}')
        i.fp-icon.fp-glyph-plus
        span {{ t('exclusivesPage.empty.create') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column.-expand
          input.fp-input-text(v-model='terms' :placeholder='t(\'exclusivesPage.search\')' data-test='terms-input')
        .fp-horizontal-spacing--column
          .fp-checkable-element
            .fp-checkable-element--checkbox
              input.fp-checkbox#visible-checkbox(v-model='showNotVisibleExclusives' type='checkbox' data-test='visibility-checkbox')
            .fp-checkable-element--label
              label(for='visible-checkbox') {{ t('auctionsPage.exclusives.showNotVisibleExclusives') }}
    .fp-vertical-spacing--line
      p(v-if='!filteredExclusives.length' data-test='no-filtered-exclusives') {{ t('exclusivesPage.noResults') }}
      table.fp-table.-with-actions(v-if='filteredExclusives.length' data-test='exclusives-table')
        thead
          tr
            th.-shrink
            th {{ t('exclusivesPage.table.slug') }}
            th {{ t('exclusivesPage.table.category') }}
            th {{ t('exclusivesPage.table.startingPrice') }}
            th {{ t('exclusivesPage.table.bids') }}
            th {{ t('exclusivesPage.table.likes') }}
            th.-shrink
        tbody
          tr(v-for='(exclusive, index) in filteredExclusives' :key='index' :data-test='`exclusive-${index}`')
            td
              .fp-image.-rounded
                img(:src='exclusive.imageUrl' alt='Exclusive image')
            td(:title='exclusive.name')
              .fp-icon-with-text
                router-link.fp-link(:to='{ name: \'updateExclusive\', params: { clubSlug: club.slug, exclusiveSlug: exclusive.slug  } }')
                  span.fp-text(data-test='slug') {{ exclusive.slug }}
                i.fp-icon.fp-glyph-eye-slash.-s18.-color-shade-400(v-if='!exclusive.visible' data-test='not-visible-icon')
            td
              span.fp-tag(data-test='category')
                i.fp-icon(:class='exclusive.categoryIcon')
                span.fp-tag--text {{ t(`exclusive.categories.${exclusive.category}`) }}
            td {{ exclusive.price.totalCost.text }}
            td {{ exclusive.auctionLot.bids.length }}
            td(data-test='likes')
              .fp-horizontal-spacing.-s-sm.-items-center.-justify-end
                .fp-text {{ exclusive.likes }}
                i.fp-icon.-s16.fp-glyph-heart.-color-danger
            td
              DropdownVue(:name='`actions-exclusive-${exclusive.slug}`')
                DropdownItemVue(:id='exclusive.slug' :text='t(\'exclusivesPage.actions.update\')' icon='edit' :routeLocation='{ name: \'updateExclusive\', params: { clubSlug: club.slug, exclusiveSlug: exclusive.slug  } }' :data-test='`edit-exclusive-${exclusive.slug}`')
                DropdownItemVue(:text='t(\'exclusivesPage.actions.clone\')' icon='copy' :routeLocation='{ name: \'createExclusive\', params: { clubSlug: club.slug }, query: { from: `${club.slug}/${exclusive.slug}`} }' :data-test='`clone-exclusive-${exclusive.slug}`')
                DropdownSeparatorVue
                DropdownItemVue(:text='t(\'exclusivesPage.actions.see\')' icon='arrow-square-in' :href='`${club.webappUrl}/exclusives/${exclusive.slug}`' :data-test='`go-to-exclusive-${exclusive.slug}`')
