import { TranslatableField } from '@/common/domain/TranslatableField';
import { Translation } from '@/common/domain/Translation';
import { Language } from '@/common/domain/Language';
import { Optional } from '@/common/domain/Optional';

export interface BackendTranslation {
  language: Language;
  value: string;
}

export const fromBackendTranslation = <T extends TranslatableField>(translation: BackendTranslation): Translation<T> => ({
  language: translation.language,
  value: translation.value.replaceAll('\\n', '\n') as T,
});

export const toBackendTranslations = <T extends TranslatableField>(
  translations: Optional<Translation<T>[]>
): BackendTranslation[] | undefined => {
  if (translations.isEmpty()) {
    return undefined;
  }

  return translations.map(actualValues => actualValues.map(toBackendTranslation<T>)).orElseUndefined();
};

export const toBackendTranslation = <T extends TranslatableField>(translation: Translation<T>): BackendTranslation => ({
  language: translation.language,
  value: translation.value,
});
