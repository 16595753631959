include ../../../club/loading-table.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    h2.fp-title.-color-shade-700 {{ t('auctionsPage.lastBids') }}
  .fp-vertical-spacing--line(v-if='bids.isLoading()' data-test='loading-bids')
    +loading-table
  .fp-vertical-spacing--line(v-if='!hasBids' data-test='no-bids')
    p {{ t('auctionsPage.noBids') }}
  .fp-vertical-spacing--line(v-else)
    table.fp-table
      thead
        tr
          th {{ t('auctionsPage.bidsTable.date') }}
          th {{ t('auctionsPage.bidsTable.exclusive') }}
          th {{ t('auctionsPage.bidsTable.bidder') }}
          th {{ t('auctionsPage.bidsTable.price') }}
      tbody
        tr(v-for='(bid, index) in bids.value()' :key='index' :data-test='`bids-${index}`')
          td(:title='bid.date.toHuman()')
            RelativeTimeVue(:date='bid.date.date')
          td
            .fp-horizontal-spacing.-items-center.-s-md
              .fp-horizontal-spacing--column
                .fp-image.-rounded
                  ExclusiveImageVue(:url='bid.exclusive.imageUrl')
              .fp-horizontal-spacing--column {{ bid.exclusive.name }}
          td
            .fp-horizontal-spacing.-items-center.-s-md
              .fp-horizontal-spacing--column
                .fp-image.-circled
                  FanImageVue(:url='bid.bidder.pictureUrl')
              .fp-horizontal-spacing--column {{ bid.bidder.firstName }} {{ bid.bidder.lastName }}
          td {{ bid.price.totalCost.text }}
