import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/common/primary/loader/Loader';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { fanRepositoryKey } from '@/staff/domain/fan/FanRepository';
import { FairplayerButtonVue } from '@/common/primary/button';
import { createReferralModal } from '@/common/primary/modal/Modals';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { FanUi, fromFan } from '@/staff/primary/fan/Fan.ui';
import { KycStatus } from '@/staff/domain/fan/KycStatus';
import { giveawayRepositoryKey } from '@/staff/domain/club/giveaway/GiveawayRepository';
import {
  fromGiveawayParticipation,
  GiveawayParticipationUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipation.ui';
import { GiveawayParticipationAnswersVue } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/giveaway-participation-answers';
import { FanImageVue } from '@/common/primary/fairplayer-image/fan-image';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { CreateReferralModalOptions } from '@/staff/primary/fan/create-referral-modal/CreateReferralModalOptions';

export default defineComponent({
  name: 'FansTable',

  components: { GiveawayParticipationAnswersVue, FairplayerButtonVue, FanImageVue },

  setup() {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const giveawayRepository = inject(giveawayRepositoryKey)!;

    const logger = inject(loggerKey)!;
    const alertBus = inject(alertBusKey)!;
    const modalBus = inject(modalBusKey)!;
    const globalWindow = inject(globalWindowKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const fans = ref(Loader.loading<FanUi[]>());
    const knownFans = computed(() =>
      fans.value
        .value()
        .filter(fan => fan.kycStatus !== KycStatus.READY_FOR_KYC)
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    );
    const invitedFans = computed(() =>
      fans.value
        .value()
        .filter(fan => fan.kycStatus === KycStatus.READY_FOR_KYC)
        .sort((a, b) => b.referral.creationDate.date.getTime() - a.referral.creationDate.date.getTime())
    );
    const hasKnownFans = computed(() => !fans.value.isLoading() && knownFans.value.length > 0);
    const hasInvitedFans = computed(() => !fans.value.isLoading() && invitedFans.value.length > 0);
    const hasKnownFanWithCompany = computed(() => !fans.value.isLoading() && knownFans.value.some(fan => fan.companyInfo !== undefined));

    const giveawayParticipations = ref(Loader.loading<GiveawayParticipationUi[]>());

    const dueDiligenceApproving = ref(false);

    const participationFor = (email: string) => giveawayParticipations.value.value().find(p => p.email === email);

    const referrerNameFor = (id: string) => knownFans.value.find(fan => fan.id === id)!.fullName;

    onMounted(async () => {
      await listFans();
      await listGiveawayParticipations();
    });

    const listGiveawayParticipations = async () => {
      const giveaways = await giveawayRepository.list(club.slug);

      return giveawayRepository
        .listGiveawayParticipations(club.slug, giveaways[0].id)
        .then(retrievedParticipations => {
          giveawayParticipations.value.loaded(retrievedParticipations.map(fromGiveawayParticipation));
        })
        .catch(error => {
          giveawayParticipations.value.loaded([]);
          logger.error('Failed to retrieve giveaway participations', error);
        });
    };

    const listFans = async () => {
      await fanRepository
        .list(club.slug)
        .then(retrievedFans => fans.value.loaded(retrievedFans.map(fromFan)))
        .catch(error => {
          fans.value.loaded([]);
          logger.error('Failed to retrieve fans', error);
        });
    };

    const approveDueDiligence = async (fan: FanUi, event: Event) => {
      if (!globalWindow.confirm(t('fans.confirmApproveDueDiligence'))) {
        event.preventDefault();
        return;
      }

      dueDiligenceApproving.value = true;
      return fanRepository
        .approveDueDiligence(club.slug, fan.id)
        .then(() => {
          alertBus.alert('toasts.success.fans.dueDiligenceApproved', 'success');

          return listFans();
        })
        .catch(error => {
          logger.error('Failed to approve due diligence', error);
        })
        .finally(() => {
          dueDiligenceApproving.value = false;
        });
    };

    const openCreateReferralModal = () => {
      const modal = createReferralModal();
      modalBus.open<CreateReferralModalOptions>({
        component: modal,
        options: {
          created: listFans,
        },
      });
    };

    return {
      club,
      dueDiligenceApproving,
      referrerNameFor,
      participationFor,
      giveawayParticipations,
      fans,
      knownFans,
      invitedFans,
      hasKnownFans,
      hasInvitedFans,
      hasKnownFanWithCompany,
      approveDueDiligence,
      openCreateReferralModal,
      t,
      KycStatus,
      FairplayerFallbackImageUi,
    };
  },
});
