include ../../club/loading-table.mixin.pug

template(v-if='postsList.isLoading()')
  +loading-table
template(v-else)
  .fp-vertical-spacing(v-if='!hasPosts' data-test='no-posts')
    .fp-vertical-spacing--line.-align-center
      i.fp-icon.fp-glyph-calendar-x.-s50.-color-shade-300
    .fp-vertical-spacing--line.-align-center
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold.-text-lg {{ t('postsPage.empty.title') }}
        .fp-vertical-spacing--line.-align-center {{ t('postsPage.empty.noPosts') }}
    .fp-vertical-spacing--line.-justify-center
      router-link.fp-link.fp-icon-with-text(:to='{name: "createPost"}' data-test='create-post')
        i.fp-icon.fp-glyph-plus
        | {{ t('postsPage.empty.create') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='posts-table')
        thead
          tr
            th {{ t('postsPage.table.date') }}
            th.-shrink
            th {{ t('postsPage.table.slug') }}
            th {{ t('postsPage.table.name') }}
            th {{ t('postsPage.table.program') }}
            th.-shrink
        tbody
          tr(v-for='(post, index) in postsList.value()' :key='index' :data-test='`post-${index}`')
            td
              code {{ post.date.toHuman() }}
            td(:title='post.name')
              .fp-image.-rounded
                img(:src='post.imageUrl' alt='Post image')
            td(:title='post.name')
              router-link.fp-link(:to='{ name: \'updatePost\', params: { clubSlug: club.slug, postSlug: post.slug  } }')
                span.fp-text(data-test='slug') {{ post.slug }}
            td {{ post.name }}
            td
              span(v-if='post.programSlug' data-test='programSlug') {{ post.programSlug }}
              span.fp-text.-color-shade-400(v-else data-test='no-program') {{ t('postsPage.noPrograms') }}
            td
              DropdownVue(:name='`actions-post-${post.slug}`')
                DropdownItemVue(:id='post.slug' :text='t(\'postsPage.actions.update\')' icon='edit' :routeLocation='{ name: \'updatePost\', params: { clubSlug: club.slug, postSlug: post.slug  } }' :data-test='`edit-post-${post.slug}`')
                DropdownSeparatorVue
                DropdownItemVue(:text='t(\'postsPage.actions.see\')' icon='arrow-square-in' :href='`${club.webappUrl}/news/${post.slug}`' :data-test='`go-to-post-${post.slug}`')
