import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { Loader } from '@/common/primary/loader/Loader';
import router from '@/router/router';
import { Post } from '@/staff/domain/post/Post';
import { postRepositoryKey } from '@/staff/domain/post/PostRepository';
import { PostFormUi, fromPost, toPostToUpdate } from '@/staff/primary/post/post-form/PostForm.ui';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PostFormVue } from '@/staff/primary/post/post-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { isPostToUpdateEmpty } from '@/staff/domain/post/PostToUpdate';

export default defineComponent({
  name: 'UpdatePost',

  components: {
    PostFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    postSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const postRepository = inject(postRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const alertBus = inject(alertBusKey)!;
    const post = ref<Post>();
    const postForm = ref(Loader.loading<PostFormUi>());
    const updating = ref(false);

    onMounted(() => {
      postRepository
        .getBySlug(props.clubSlug, props.postSlug)
        .then(retrievedPost => {
          setFormValue(retrievedPost);
        })
        .catch(error => logger.error('Failed to retrieve post', error));
    });

    const onConfirmUpdate = (updatedPostForm: PostFormUi): Promise<void> => {
      const postToUpdate = toPostToUpdate(updatedPostForm, post.value!, props.clubSlug);
      if (isPostToUpdateEmpty(postToUpdate)) {
        return Promise.resolve();
      }

      updating.value = true;

      return postRepository
        .update(postToUpdate)
        .then(retrievedPost => {
          setFormValue(retrievedPost);
          alertBus.alert('toasts.success.post.update', 'success');
          router.push({ name: 'updatePost', params: { clubSlug: props.clubSlug, postSlug: updatedPostForm.slug } });
        })
        .catch(error => logger.error('Failed to update post', error))
        .finally(() => (updating.value = false));
    };

    const setFormValue = (retrievedPost: Post) => {
      post.value = retrievedPost;
      postForm.value.loaded(fromPost(retrievedPost, club.languages));
    };

    const onGoBack = () => {
      router.push({ name: 'posts', params: { clubSlug: props.clubSlug } });
    };

    return {
      postForm,
      post,
      updating,
      onGoBack,
      onConfirmUpdate,
      t,
    };
  },
});
