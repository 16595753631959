import { Optional } from '@/common/domain/Optional';
import { Translation } from '@/common/domain/Translation';
import { Url } from '@/common/domain/Url';
import { ClubTaxDeductionText } from '@/staff/domain/club/ClubTaxDeductionText';

export interface ClubTaxDeductionToUpdate {
  allow: Optional<boolean>;
  signatureUrl: Optional<Url>;
  textTranslations: Optional<Translation<ClubTaxDeductionText>[]>;
}

export const isClubTaxDeductionToUpdateEmpty = (clubToUpdate: ClubTaxDeductionToUpdate) =>
  Object.keys(clubToUpdate)
    .map(key => key as keyof ClubTaxDeductionToUpdate)
    .map(key => clubToUpdate[key])
    .every(field => field.isEmpty());
