include ../../club/loading-table.mixin.pug

template(v-if='giveaways.isLoading()')
  +loading-table
template(v-else)
  .fp-vertical-spacing(v-if='!hasGiveaways' data-test='no-giveaways')
    .fp-vertical-spacing--line.-align-center
      i.fp-icon.fp-glyph-close-circle.-s50.-color-shade-300
    .fp-vertical-spacing--line.-align-center
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold.-text-lg {{ t('giveawaysPage.empty.title') }}
        .fp-vertical-spacing--line.-align-center {{ t('giveawaysPage.empty.noGiveaways') }}
    .fp-vertical-spacing--line.-justify-center
      router-link.fp-link.fp-icon-with-text(:to='{ name: \'createGiveaway\' }' data-test='create-giveaway')
        i.fp-icon.fp-glyph-plus
        | {{ t('giveawaysPage.empty.create') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table.-with-actions(data-test='giveaways-table')
        thead
          tr
            th(v-if='isGiveaway' data-test='title-status') {{ t('giveawaysPage.table.status.title') }}
            th(v-if='isGiveaway' data-test='title-start-date') {{ t('giveawaysPage.table.startDate') }}
            th(v-if='isGiveaway' data-test='title-end-date') {{ t('giveawaysPage.table.endDate') }}
            th {{ t('giveawaysPage.table.questionNumber') }}
            th {{ t('giveawaysPage.table.participationNumber') }}
            th(v-if='isGiveaway' data-test='title-partner') {{ t('giveawaysPage.table.partner') }}
            th.-shrink
        tbody
          tr(v-for='(giveaway, index) in giveaways.value()' :key='index' :data-test='`giveaway-${index}`')
            td(v-if='isGiveaway' data-test='status')
              span.fp-tag(:class='giveaway.tagClass' data-test='status-tag') {{ t(`giveawaysPage.table.status.${giveaway.status}`) }}
            td(v-if='isGiveaway' data-test='start-date')
              code {{ giveaway.startDate.toHuman() }}
            td(v-if='isGiveaway' data-test='end-date')
              code {{ giveaway.endDate.toHuman() }}
            td {{ giveaway.questions.length }}
            td {{ giveaway.numberOfParticipations }}
            td(v-if='isGiveaway' data-test='partner')
              span(v-if='giveaway.partner' data-test='partner') {{ giveaway.partner.name }}
              span.fp-text.-color-shade-400(v-else data-test='no-partner') {{ t('giveawaysPage.noPartner') }}
            td
              DropdownVue(:name='`actions-giveaways-${giveaway.id}`')
                DropdownItemVue(v-if='giveaway.status !== "CLOSED" && giveaway.status !== "TO_CLOSE"' :text='t(\'giveawaysPage.actions.update\')' icon='edit' :routeLocation='{ name: \'updateGiveaway\', params: { clubSlug: clubSlug, giveawayId: giveaway.id  } }' :data-test='`edit-giveaway-${giveaway.id}`')
                template(v-if='isGiveaway')
                  DropdownItemVue(v-if='giveaway.status !== "SCHEDULED"' :id='\'giveawayId\'' :text='t(\'giveawaysPage.actions.results\')' icon='piechart' :routeLocation='{ name: \'giveawayResults\', params: { clubSlug: clubSlug, giveawayId: giveaway.id  } }' :data-test='`giveaway-results-${giveaway.id}`')
                  DropdownItemVue(:text='t(\'giveawaysPage.actions.clone\')' icon='copy' :routeLocation='{ name: \'createGiveaway\', params: { clubSlug: clubSlug }, query: { from: `${clubSlug}/${giveaway.id}`} }' :data-test='`clone-giveaway-${giveaway.id}`')
                  template(v-if='giveaway.status === "ACTIVE"')
                    DropdownSeparatorVue
                    DropdownItemVue(:href='giveaway.url' :text='t(\'giveawaysPage.actions.see\')' icon='arrow-square-in' :data-test='`see-giveaway-${giveaway.id}`')
