import { GiveawayAnswerToCreate } from '@/staff/domain/club/giveaway/GiveawayAnswerToCreate';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { GiveawayAnswerText } from '@/staff/domain/club/giveaway/GiveawayAnswerText';

export interface BackendGiveawayAnswerToCreate {
  textTranslations: BackendTranslation[];
}

export const toBackendGiveawayAnswerToCreate = (giveawayAnswerToCreate: GiveawayAnswerToCreate): BackendGiveawayAnswerToCreate => ({
  textTranslations: giveawayAnswerToCreate.textTranslations.map(toBackendTranslation<GiveawayAnswerText>),
});
