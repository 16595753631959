.fp-staff-layout
  .fp-staff-layout--menu
    router-link.fp-staff-layout--menu--logo(:to='{name: "clubOverview"}')
      img.fp-illustration.-fairplayer-logo
      img.fp-illustration.-logo.-xs
    template(v-if='!authorizations.isLoading() && clubLoaded')
      .fp-staff-layout--menu--items(data-test='menu-items')
        router-link.fp-left-menu-item(v-for='(menuItem, index) in menuItems' :key='index' :class='{"-active": currentRouterTabName === menuItem.routerTab}' :to='{ name: menuItem.routeName}' :title='t(`menu.${menuItem.translateKey}`)' :data-test='`${ menuItem.routeName }`')
          i.fp-icon.-s18(:class='`fp-glyph-${menuItem.icon}`')
          .fp-left-menu-item--text {{ t(`menu.${menuItem.translateKey}`) }}
      .fp-staff-layout--menu--footer(v-if='showFooter' data-test='footer')
        router-link.fp-left-menu-footer-item(data-test='logo' :to='{ name: \'updateClub\', params: { clubSlug: clubUi.value().slug } }' :class='{"-active": currentRouterTabName === RouterTab.CLUB}')
          .fp-image
            img.-contain(:src='clubUi.value().logoUrl' alt='Club Logo')
          .fp-left-menu-footer-item--text {{ clubUi.value().platformName }}
  .fp-staff-layout--body
    AnnouncementVue(v-if='displayDevEnvironmentAnnouncement' :announcement-ui='devEnvironmentAnnouncementUi' @close='hideDevAnnouncement' data-test='dev-announcement')
    router-view(v-if='clubLoaded')
    NotFoundVue(v-if='clubNotFound' :not-found-ui='clubNotFoundUi()')
  .fp-staff-layout--toaster
    ToastsListVue
  ModalVue
  HighlightedMediaVue
