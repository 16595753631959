PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column.-expand
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-gift.-s18
            .fp-horizontal-spacing--column
              h2.fp-title.-color-shade-700(data-test='title') {{ t(`giveawaysPage.title${isGiveaway ? '': 'DueDiligence'}`) }}
        .fp-horizontal-spacing--column(v-if='isGiveaway')
          router-link.fp-link.-no-text-decoration(:to='{ name: \'createGiveaway\', params: { clubSlug }}' data-test='create-giveaway')
            FairplayerButtonVue(icon='plus' :text='t(\'giveawaysPage.createGiveaway\')')
    .fp-vertical-spacing--line
      GiveawaysTableVue(:club-slug='clubSlug' :is-giveaway='isGiveaway')
