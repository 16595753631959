import { GiveawayQuestionToCreate } from '@/staff/domain/club/giveaway/GiveawayQuestionToCreate';
import {
  BackendGiveawayAnswerToCreate,
  toBackendGiveawayAnswerToCreate,
} from '@/staff/secondary/club/giveaway/BackendGiveawayAnswerToCreate';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { GiveawayQuestionTitle } from '@/staff/domain/club/giveaway/GiveawayQuestionTitle';
import { GiveawayQuestionPreTitle } from '@/staff/domain/club/giveaway/GiveawayQuestionPreTitle';

export interface BackendGiveawayQuestionToCreate {
  answers: BackendGiveawayAnswerToCreate[];
  required: boolean;
  quizMode: boolean;
  multiline: boolean;
  rightAnswerText?: string;
  titleTranslations: BackendTranslation[];
  preTitleTranslations?: BackendTranslation[];
}

export const toBackendGiveawayQuestionToCreate = (giveawayQuestionToCreate: GiveawayQuestionToCreate): BackendGiveawayQuestionToCreate => ({
  answers: giveawayQuestionToCreate.answers.map(toBackendGiveawayAnswerToCreate),
  required: giveawayQuestionToCreate.required,
  quizMode: giveawayQuestionToCreate.quizMode,
  multiline: giveawayQuestionToCreate.multiline,
  rightAnswerText: giveawayQuestionToCreate.rightAnswer.map(answer => answer.textTranslations[0].value).orElseUndefined(),
  titleTranslations: giveawayQuestionToCreate.titleTranslations.map(toBackendTranslation<GiveawayQuestionTitle>),
  preTitleTranslations: giveawayQuestionToCreate.preTitleTranslations
    .map(translations => translations.map(toBackendTranslation<GiveawayQuestionPreTitle>))
    .orElseUndefined(),
});
