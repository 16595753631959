import { defineComponent, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Coin } from '@/common/domain/token/Coin';

export default defineComponent({
  name: 'Converter',

  props: {
    coinsAmount: {
      type: Number,
      required: true,
    },
    coin: {
      type: Object as PropType<Coin>,
      required: true,
    },
    fiatOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    touched: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['update-coins-amount'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const coinsPrice = ref(props.coinsAmount);
    const fiatPrice = ref(props.coinsAmount * props.coin.price);

    const roundToTwoDecimals = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

    const updatedFiatAmount = (event: Event) => {
      const fiatAmountUpdated = +(<HTMLInputElement>event.target).value;
      coinsPrice.value = roundToTwoDecimals(fiatAmountUpdated / props.coin.price);

      emit('update-coins-amount', { value: coinsPrice.value });
    };

    const updatedCoinsAmount = (event: Event) => {
      const coinsAmountUpdated = +(<HTMLInputElement>event.target).value;
      fiatPrice.value = roundToTwoDecimals(coinsAmountUpdated * props.coin.price);

      emit('update-coins-amount', { value: coinsAmountUpdated });
    };

    watch(
      () => [props.coinsAmount],
      () => {
        fiatPrice.value = roundToTwoDecimals(props.coinsAmount * props.coin.price);
        coinsPrice.value = props.coinsAmount;
      }
    );

    return {
      updatedFiatAmount,
      updatedCoinsAmount,
      coinsPrice,
      fiatPrice,
      confirm,
      t,
    };
  },
});
